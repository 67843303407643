import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from '../config';
import { pos_api_url } from '../config';

// let url         = 'https://us-central1-llaoz-sandbox.cloudfunctions.net/llaoz/';
let url         = "https://03lj0nhy0l.execute-api.us-east-2.amazonaws.com/prod/"
let subscribe   = 'subscribe';
let contact     = 'contact';
let blog        = 'articles';

let article     = 'article/';//
let comment     = '/comment';//


export const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
let phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const username_regex = /^[a-zA-Z0-9]{4,16}$/;
export const password_regex = /^.{8,30}$/;
export const brand_name_regex = /^[a-zA-Z0-9\s]{2,100}$/;
export const name_regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ ]{2,32}$/;
export const branch_name_regex = /^[a-zA-Z0-9À-ÖØ-öø-ÿ]{2,32}$/;

export const logDebug = (message) => {
    console.log("Kilbo: " +  message)
}

let dateOptions = {  year: 'numeric', month: 'long' };
export const convertDateLocation = (date) =>{
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)

    return fecha.replace('de',' ').toUpperCase()
}

export const postSubscribe = async(email) =>{
    let ruta = url + subscribe
    let flag = false
    let method = {
        method:"POST",
        body: JSON.stringify({"email":email}),
        headers:{
            'Content-Type': 'application/json'
        }
    };

    await fetch(ruta, method)
    .then((response) =>{
        if(response.status === 200){
            flag = true
            toast.success("Subscripción exitosa")
        } else{
            toast.warn("Correo invalido")
        }
    })
    return flag
}

export const postContact = async(name, email, phone, message) =>{
    let ruta = url + contact
    let obj = {}

    obj.name    = name
    obj.email   = email
    obj.phone   = phone
    obj.message = message

    let method = {
        method:"POST",
        body: JSON.stringify(obj),
        headers:{
            'Content-Type': 'application/json'
        }
    };

    await fetch(ruta, method)
    .then((response) =>{
        if(response.status === 200){
            toast.success("Datos enviados")
            console.log(response.status)
        } else{
            toast.warn("Correo invalido")
            console.log(response.status)
        }
    })

}

export const postComment = async(name, email, message, id) =>{
    let ruta = url + article + id + comment
    let obj = {}
    let status = 0;
    obj.name    = name
    obj.email   = email
    obj.message = message
    console.log("El id es: ",id);
    
    let method = {
        method:"POST",
        body: JSON.stringify(obj),
        headers:{
            'Content-Type': 'application/json'
        }
    };

    await fetch(ruta, method)
    .then((response) =>{
        if(response.status === 200){
            toast.success("Datos enviados")
            console.log(response.status)
        } else{
            toast.warn("Correo invalido")
            console.log(response.status)
        }
        status = response.status
    })
    return status
}

export const getBlog = async() =>{
    let ruta = url + blog
    let array = []
    let method = {
        method:"GET",
    };

    await fetch(ruta, method)
    .then((response) =>{
        if(response.status === 200){
            return response.json()
        } else{
            console.log(response.status)
        }
    }).then((res) =>{
        array.push(res)
    })
    return array[0]
}   

export const filterData = async(data, id) =>{
    let flag = false
    let dataArray = []
    data.forEach((item) =>{
        if(item.id === parseInt(id) || item.id === id){
            dataArray.push(item)
        }
    })
    if(dataArray.length === 0){
        flag = true
    }
    return {"res":dataArray, "flag":flag}
}

export const subscribeValidations = (email) =>{
    
    let flag = false
    if(email.match(email_regex)){
        // toast.success("Datos agregados")
        flag = true
    } else{
        toast.warn("Correo invalido")
    }
    return flag
}

export const contactValidations = (name, email, phone, message) =>{
    let flag = false
    if(email.match(email_regex) && name && phone.match(phoneformat) && message){
        flag = true
    } else{
        toast.warn("Datos invalidos")
    }
    return flag
}

export const commentValidations = (name, email, message) =>{
    let flag = false
    if(name && email.match(email_regex) && message){
        flag = true
    } else{
        toast.warn("Datos invalidos")
    }
    return flag
}

export const modalValidations = (name, email, phone, plan) =>{
    let flag = false
    if(name && email.match(email_regex) && phone.match(phoneformat) && plan){
        flag = true
    } else{
        toast.warn("Datos invalidos")
    }
    return flag
}

export const signOnDataValidation = (data) => {
    let valid_data = true;
    let errors = [];

    if (!brand_name_regex.test(data.brandName)) {
        valid_data = false;
        errors.push('Nombre de la empresa. Sin caracteres especiales.');
    }
    
    if ( 
        (data.plan === 'basic' && (data.usersAllowed < 2 || data.usersAllowed > 20)) ||
        (data.plan === 'professional' && data.usersAllowed < 4) ) {
            valid_data = false;
            errors.push('Plan. Verifica que tu plan este bien definido.');
    }

    if (!username_regex.test(data.username)) {
		valid_data = false;
        errors.push('Nombre de usuario. Sin caracteres especiales, letras y numeros, 4 a 16 caracteres.');
    }
    
    if (!password_regex.test(data.userPassword)) {
        valid_data = false;
        errors.push('Contraseña. 8 a 30 caracteres.');
    }

    if (data.userPassword !== data.userPassword_confirmation) {
        valid_data = false;
        errors.push('Contraseñas no coinciden.');
    }

    if (!email_regex.test(data.email)) {
        valid_data = false;
        errors.push('Correo. Parece que el correo electronico no tiene un formato valido.');
    }

    if (!name_regex.test(data.name)) {
		valid_data = false;
        errors.push('Nombre(s). Sin caracteres especiales, de 2 a 32 caracteres.');
    }

	if (!name_regex.test(data.lname)) {
		valid_data = false;
        errors.push('Apellidos. Sin caracteres especiales, de 2 a 32 caracteres.');
    }

    if (!branch_name_regex.test(data.shopName)) {
        valid_data = false;
        errors.push('Nombre de la sucursal. Sin caracteres especiales ni espacios, de 2 a 32 caracteres.');
    }

    // if ( data.shopStreet === "" ) {
	// 	valid_data = false;
    //     errors.push('Calle de la sucursal. ');
    // }

    // if ( isNaN(data.shopExtNumber) || data.shopExtNumber === "" ) {
	// 	valid_data = false;
    //     errors.push('Numero ext de la sucursal. ');
    // }

    // if ( isNaN(data.shopZip) || data.shopZip === "" ) {
	// 	valid_data = false;
    //     errors.push('Codigo postal de la sucursal. ');
    // }

    // if ( data.shopNeighborhood === "" ) {
	// 	valid_data = false;
    //     errors.push('Colonia de la sucursal. ');
    // }

    // if ( data.shopCity === "" ) {
	// 	valid_data = false;
    //     errors.push('Ciudad de la sucursal. ');
    // }

    // if ( data.shopState === "" ) {
	// 	valid_data = false;
    //     errors.push('Estado de la sucursal. ');
    // }

    // if ( data.shopCountry === "" ) {
	// 	valid_data = false;
    //     errors.push('Pais de la sucursal. ');
    // }
    
    if (!valid_data) {
        toast.warn('Corrige los siguientes campos', { autoClose: false, draggablePercent: 60 });
        for(let error of errors) {
            toast.warn(error, { autoClose: false, draggablePercent: 60 });
        }
    }

    return valid_data;
}

export const kilboSignOn = async (data) => {

    let method = {
        method:"POST",
        body: JSON.stringify(data),
        headers:{
            'Content-Type': 'application/json',
        }
    };

    return fetch(`${pos_api_url}/commerce/`, method);

}

export const addLead = async (name, email, phone, plan) => {
    let method = {
        method:"POST",
        body: JSON.stringify({
            email: email,
            name: name,
            phone: phone,
            plan: plan,
            status_lead: 'Sin iniciar pruebas gratis',
        }),
        headers:{
            'Content-Type': 'application/json',
        }
    };

    return fetch(`${url}lead`, method);
}

/**
 * 
 * @param {String} email email of the lead to update
 * @param {String} status Lead status to set. posibble values => "Sin iniciar pruebas gratis", "Pruebas gratis iniciada", "Prueba gratis vencida", "Cliente", "Ex-cliente", "No moletar" (Ya no quiero recibir correos de Kilbo)
 * @returns {Promise} fetch request response
 */
export const updateLead = async (email, status) => {
    let method = {
        method:"PATCH",
        body: JSON.stringify({
            email: email,
            status_lead: status
        }),
        headers:{
            'Content-Type': 'application/json',
        }
    };

    return fetch(`${url}lead`, method);
}

// Los leads no se eliminaran, se actualizara su status
export const deleteLead = async (email) => {
    let method = {
        method:"DELETE",
        body: JSON.stringify({
            email: email
        }),
        headers:{
            'Content-Type': 'application/json',
        }
    };


    return fetch(`${url}lead`, method);
}