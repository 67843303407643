import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Link, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import {
    kilbo_tutorial_youtube_video,
    youtube_kilbo_channel,
    kilbo_tutorials_list
} from '../../values';
import '../../fonts/font.css';
import {  } from '../../utils/BaseBuilder';
import { Colors } from '../../values';
import { enviroment, pos_url, pos_web_url } from '../../config';

const useStyles = makeStyles(theme => ({
    title:{
        minWidth: '100%',
        textAlign: 'center',
        fontSize:25,
        fontFamily:'Medium'
    },
    subtitle:{
        minWidth: '100%',
        textAlign: 'center',
        fontSize:20,
        fontFamily:'Medium'
    },
    videosGrid:{
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    link:{
        textDecoration: 'none !important',
    },
    myShopInfoGrid:{
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
    }
}));

function SuccessfulSignOn () {
    const classes = useStyles();
    const urlParams = new URLSearchParams(window.location.search);
    const [shopname, setshopname]   = useState(urlParams.has('brand')   ? urlParams.get('brand') : '');
    const [shopkey, setShopkey]     = useState(urlParams.has('brand')   ? urlParams.get('brand').toLowerCase().replace(/ /gi, '') : '');
    const [username, setUsername]   = useState(urlParams.has('user')    ? urlParams.get('user') : '');
    const [password, setPassword]   = useState(urlParams.has('passwd')  ? urlParams.get('passwd') : '');
    const online_store_url = `${pos_web_url}/${shopkey}`;

    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    const isBase64 = (encodedString) => {
        var regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return regexBase64.test(encodedString);   // return TRUE if its base64 string.
    }

    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <div style={{height:100}}/>

                <h2 className={classes.title} style={{color: Colors.gray}}>Felicidades, tu tienda "{shopname}" ha sido creada exitosamente</h2>

                <div style={{paddingBottom:50}}/>

                <h3 className={classes.subtitle} style={{color: Colors.gray}}>Acceso a tu punto de venta y tienda en linea</h3>
                <Grid container className={classes.myShopInfoGrid}>
                    <div>
                        <h4>Mi punto de venta</h4>
                        <Button variant="outlined" color="primary">
                            <Link
                                href={pos_url}
                                target="_blank"
                                className={classes.link}>
                                Ir a mi punto de venta
                            </Link>
                        </Button>
                        <h5>Tienda: {shopkey}</h5>
                        <h5>Usuario: {username}</h5>
                        <h5>Contraseña: {isBase64(password) && window.atob(password)}</h5>
                    </div>
                    <div>
                        <h4>Mi tienda en linea</h4>
                        <Button variant="outlined" color="primary">
                            <Link
                                href={online_store_url}
                                target="_blank"
                                className={classes.link}>
                                Ir a mi tienda en linea
                            </Link>
                        </Button>
                        <h5>Tu dominio por defecto es:</h5>
                        <h5>
                            {online_store_url}
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        {`
                                            A travez de tu punto de venta podras solicitar asesoria para obtener un dominio personalizado, 
                                            ej: ${shopkey}.com. ** Tener un dominio personalizado implica un cargo adicional **
                                        `}
                                    </React.Fragment>
                                }
                                placement="top">
                                    <Info />
                            </Tooltip>
                        </h5>
                    </div>
                </Grid>

                <div style={{paddingBottom:50}}/>

                <h3 className={classes.subtitle} style={{color: Colors.gray}}>Te recomendamos ver este video corto para comenzar</h3>
                <Grid container className={classes.videosGrid}>
                    <iframe
                        width="750"
                        height="420"
                        src={kilbo_tutorial_youtube_video}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </Grid>

                <div style={{paddingBottom:50}}/>

                <Grid container className={classes.videosGrid}>
                    <Button variant="outlined" color="primary">
                        <Link href={kilbo_tutorials_list} target="_blank" className={classes.link}>
                            Tutoriales de Kilbo
                        </Link>
                    </Button>
                    <Button variant="outlined" color="primary">
                        <Link href={youtube_kilbo_channel} target="_blank" className={classes.link}>
                            Canal de Kilbo
                        </Link>
                    </Button>
                </Grid>

                <div style={{paddingBottom:50}}/>

            </Container>
            <div style={{height:50}}/>
        </div>
    );
}

export default SuccessfulSignOn;