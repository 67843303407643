import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Colors} from '../../../values';
import {home_platform, home_know, home_platform_options} from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    home_platform:{
        fontSize:70,
        [theme.breakpoints.down('xs')]: {
            fontSize:50,
        },
    },
    content1:{
        marginBottom:150,
        [theme.breakpoints.down('xs')]: {
            marginBottom:80,
        },
    },
    content2:{
        marginBottom:150,
        [theme.breakpoints.down('xs')]: {
            marginBottom:80,
        },
    },
    content3:{
        width:'100%',
        [theme.breakpoints.up('sm')]: {
            width:'90%',
        },
    },
    grid:{
        display:'none',
        [theme.breakpoints.down('xs')]: {
            display:'block',
        },
    },
}));


function Platform () {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div>
            <div style={{height:200, width:'100%'}}/>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <span style={{color: Colors.gray, fontSize:25, fontFamily:'Medium'}} > {home_know} </span>
                <br/>
                <span style={{color: Colors.gray, fontFamily:'Bold'}}  className={classes.home_platform}> {home_platform} </span>
                <div style={{height:50}}/>
                <Grid container>
                    { home_platform_options.map((item, index)=> 
                        <Grid item xs={12} sm={6} key={index}>
                            <Grid container className={classes.content1}>
                                <Grid item xs={2} sm={2}>
                                    <img   src={item.image} style={{width:50, height:50,}} />
                                </Grid>
                                <Grid item xs={1} className={classes.grid} />
                                <Grid item xs={9} sm={9}>
                                    <div className={classes.content3}>
                                        <span style={{marginTop:20, color: Colors.gray, fontSize:16, fontFamily:'Regular'}} > {item.text} </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );

}

export default Platform;