import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Colors, home_hand, home_mobile_description, home_mobile, img_kilbo_mobile, start} from '../../../values';
import ModalForm from '../../global/ModalForm';
import Button from '../../global/ButtonOutline';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    phoneMobile:{
        display:'none',
        [theme.breakpoints.down('xs')]: {
            display:'block'
        },
    },
    phoneDesktop:{
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
    },
    content1:{
        marginBottom:40,
        marginTop:'30%',
        [theme.breakpoints.up('xl')]: {
            marginTop:'30%'
        },
        [theme.breakpoints.down('md')]: {
            marginTop:'20%',
        },
    },
    home_mobile:{
        fontSize:60,
        [theme.breakpoints.down('xs')]: {
            fontSize:40,
            marginTop:20,
        },
    },
}));

function Mobile () {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    const openModal = () =>{
        setOpen(true)
    }
    const callback = () =>{
        setOpen(false);
    }
    return (
        <div>
            {
                open &&
                    <ModalForm modal={open} parentCallback={callback} />
            }
            <div style={{height:100, width:'100%'}}/>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <div style={{width:'100%', height:1, background:Colors.grayLight}}/>
                <div style={{width:'100%', height:70}}/>
                <Grid container>
                    <Grid item xs={12} sm={4} className={classes.phoneDesktop}>
                        <div style={{width:'100%'}}> <img  src={img_kilbo_mobile} style={{width:'80%', marginLeft:'20%', height:'auto'}} /> </div>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={12} sm={7}>
                        <div className={classes.content1}>
                            <span style={{color: Colors.gray, fontSize:20, fontFamily:'Medium'}} > {home_hand} </span>
                            <br/>
                            <span style={{color: Colors.gray, fontFamily:'Bold'}} className={classes.home_mobile}> {home_mobile} </span>
                            <br/>
                            <span style={{color: Colors.gray, fontSize:18, fontFamily:'Regular'}} > {home_mobile_description} </span>
                            <div onClick={openModal} style={{marginTop:30}}>
                                <Button text={start} color='blue' />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div style={{width:'100%'}} className={classes.phoneMobile}> 
                    <div style={{textAlign:'center'}}>
                        <img  src={img_kilbo_mobile} style={{width:'80%', height:'auto'}} />
                    </div>
                </div>
                <div style={{width:'100%', height:70}}/>
                <div style={{width:'100%', height:1, background:Colors.grayLight}}/>
            </Container>
        </div>
    );
}

export default Mobile;