import React, { useState, useEffect } from 'react';
import { Container, Toolbar, Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from './Drawer';
import {Colors} from '../../values';
import {menuItem, img_logo_color, img_logo_white} from '../../values';
import '../../fonts/font.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-148916791-1');




const useStyles = makeStyles(theme => ({
    displayMenuPhone: {display:'none', [theme.breakpoints.down('xs')]: { height:100, display: 'block', position: 'absolute', background: Colors.white, paddingTop: 70, right: 0, }, },
    buttonBar:         { 
        selected:'0.3s', 
        background: Colors.white, 
        fontSize:"16px", color: 
        Colors.grayDark, 
        fontFamily:'Medium', 
        paddingRight:15,  
        paddingLeft:15, 
        paddingBottom:3, 
        borderRadius:0, 
        '&:hover': { 
                background: Colors.white, 
                color: Colors.gray, 
        }, },
    buttonBarSelected: { 
        selected:'0.3s', 
        background: Colors.white, 
        fontSize:"16px", 
        color: Colors.blue, 
        fontFamily:'Bold', 
        paddingRight:15, 
        paddingLeft:15, 
        paddingBottom:1.5, 
        borderRadius:0, 
        '&:hover': { 
            background: Colors.white, 
            color: Colors.gray,  
        }, },
    menuDesktop:{
        [theme.breakpoints.down('md')]: {
            display:'none'
        },
    },
    menuPhone:{
        display:'none',
        [theme.breakpoints.down('md')]: {
            display:'block'
        },
    },
    content1:{
        background:Colors.white, 
        // height:140, 
        paddingTop:10,
        paddingBottom:10,
        display:'flex', 
        alignItems:'center',
        [theme.breakpoints.down('md')]: {
            background:Colors.blue, 
        }
    },
    content2:{
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            textAlign:'right',
        }
    }
}));


function Navbar ({history}) {
    
    const [path, setPath] = useState('home')
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    const [scrollPosition, setSrollPosition] = useState(0);
    
    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div className={classes.content1} style={{boxShadow: (scrollPosition >= 100) ? '0px 2px 4px 2px rgba(0,0,0,0.15)' : '0px 2px 4px 2px rgba(0,0,0,0.0)'}}>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={2}>
                        <div style={{marginTop:'5px', paddingLeft:'30px'}}>
                            <Button to={`/`} component={RouterLink}>
                                <span style={{textAlign:'left', maxWidth:120}}><img   alt="logo" style={{zIndex:'5'}} src={size <= 1279 ? img_logo_white : img_logo_color} className="logo"  /></span>
                            </Button>
                        </div>
                    </Grid>

                    <Grid item xs={3} />

                    <Grid item xs={7} className={classes.content2}> 
                        <div style={{marginTop:20}} className={classes.menuDesktop}>
                            {
                                menuItem.map((item, index)=>
                                    (item.title.toLowerCase() === (window.location.pathname).replace('/', '')) ?
                                            <Button className={classes.buttonBarSelected} style={{textTransform:'none'}} 
                                                onClick={ () => setPath(item.title) }
                                                key={item.id} 
                                                to={`/${item.title.toLowerCase()}`} 
                                                component={RouterLink} > {item.title} 
                                            </Button>
                                        :
                                            window.location.pathname === '/' &&  item.title.toLowerCase() === "inicio" ?
                                                <Button className={classes.buttonBarSelected} style={{textTransform:'none'}} 
                                                    onClick={ () => setPath(item.title) }
                                                    key={item.id} 
                                                    to={`/${item.title.toLowerCase()}`} 
                                                    component={RouterLink} > {item.title} 
                                                </Button>
                                        :
                                            <Button 
                                                className={classes.buttonBar} 
                                                onClick={ () => setPath(item.title) }
                                                style={{textTransform:'none'}}  key={item.id} to={`/${item.title.toLowerCase()}`} component={RouterLink} > {item.title} 
                                            </Button>
                                )
                            }
                        </div>
                        <div className={classes.menuPhone}>
                            <Drawer />
                        </div>
                    </Grid>    
                    </Grid>
                </Container>
            
        </div>
    );

}

export default Navbar;