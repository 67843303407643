import React, {useState, useEffect} from 'react';
import { Container } from '@material-ui/core';
import {
    title_condition,
    subtitle_condition,
    tc_service_title,
    first_tc,
    second_tc,
    third_tc,
    fourth_tc,
    fifth_tc,
    sixth_tc,
    activate_acount_title,
    activate_account_owner_shop_title,
    activate_account_owner_shop_first,
    activate_account_owner_shop_second,
    activate_account_owner_shop_third,
    activate_account_employee_title,
    activate_account_employee_first,
    activate_account_employee_second,
    activate_account_employee_third,
    general_conditions_title,
    first_general_condition,
    second_general_condition,
    third_general_condition,
    fourth_general_condition,
    fifth_general_condition,
    sixth_general_condition,
    seventh_general_condition,
    eighth_general_condition,
    nineth_general_condition,
    tenth_general_condition,
    eleventh_general_condition,
    kilbo_rights_title,
    first_kilbo_right,
    second_kilbo_right,
    third_kilbo_right,
    fourth_kilbo_right,
    fifth_kilbo_right,
    sixth_kilbo_right,
    seventh_kilbo_right,
    eighth_kilbo_right,
    confidentiality_title,
    first_confidentiality,
    second_confidentiality,
    limitation_liability_title,
    first_limitation_liability,
    second_limitation_liability,
    third_limitation_liability,
    fourth_limitation_liability,
    fifth_limitation_liability,
    sixth_limitation_liability,
    seventh_limitation_liability,
    entire_agreement_title,
    entire_agreement,
    intellectual_property_title,
    first_intellectual_property,
    second_intellectual_property,
    third_intellectual_property,
    fourth_intellectual_property,
    kilbo_envios_title,
    kilbo_envios,
    payment_of_charges_title,
    first_payment_of_charges,
    second_payment_of_charges,
    third_payment_of_charges,
    fourth_payment_of_charges,
    fifth_payment_of_charges,
    sixth_payment_of_charges,
    seventh_payment_of_charges,
    eighth_payment_of_charges,
    nineth_payment_of_charges,
    cancellation_termination_title,
    first_cancellation_termination,
    first_first_cancellation_termination,
    first_second_cancellation_termination,
    first_third_cancellation_termination,
    first_fourth_cancellation_termination,
    second_cancellation_termination,
    third_cancellation_termination,
    fourth_cancellation_termination,
    fifth_cancellation_termination,
    service_price_modifications_title,
    first_service_price_modifications,
    second_service_price_modifications,
    third_service_price_modifications,
    author_rights_title,
    author_rights,
    data_protection_and_privacy_title,
    data_protection_and_privacy,
} from '../../values';
import Banner from '../global/Banner'


function Conditions(props) {
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div style={{minHeight:'80vh'}}>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner  title={title_condition} subtitle={subtitle_condition}/>
                <div style={{padding:'80px 50px 50px 50px'}}>

                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { tc_service_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_tc}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_tc}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_tc}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_tc}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_tc}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_tc}
                            </p>
                        </li>
                    </ol>

                    
                    
                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { activate_acount_title }
                    </p>

                    <p style={{color:'rgb(44, 49, 56)', fontSize:'18px', fontFamily:'SemiBold'}}>
                        { activate_account_owner_shop_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_owner_shop_first}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_owner_shop_second}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_owner_shop_third}
                            </p>
                        </li>
                    </ol>

                    <p style={{color:'rgb(44, 49, 56)', fontSize:'18px', fontFamily:'SemiBold'}}>
                        { activate_account_employee_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_employee_first}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_employee_second}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {activate_account_employee_third}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { general_conditions_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                                dangerouslySetInnerHTML={{ __html: first_general_condition }}>
                                {/* {first_general_condition} */}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_general_condition}
                            </p>
                        </li>

                        <li>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                                dangerouslySetInnerHTML={{ __html: third_general_condition }}>
                                {/* {third_general_condition} */}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_general_condition}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_general_condition}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_general_condition}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {seventh_general_condition}
                            </p>
                        </li>

                        <li>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                                dangerouslySetInnerHTML={{ __html: eighth_general_condition }}>
                                {/* {eighth_general_condition} */}
                            </p>
                        </li>

                        <li>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                                dangerouslySetInnerHTML={{ __html: nineth_general_condition }}>
                                {/* {nineth_general_condition} */}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {tenth_general_condition}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {eleventh_general_condition}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { kilbo_rights_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {seventh_kilbo_right}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {eighth_kilbo_right}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { confidentiality_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_confidentiality}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_confidentiality}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { limitation_liability_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_limitation_liability}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {seventh_limitation_liability}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { entire_agreement_title }
                    </p>
                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                        {entire_agreement}
                    </p>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { intellectual_property_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_intellectual_property}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_intellectual_property}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_intellectual_property}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_intellectual_property}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { kilbo_envios_title }
                    </p>
                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                        {kilbo_envios}
                    </p>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { payment_of_charges_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {seventh_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {eighth_payment_of_charges}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {nineth_payment_of_charges}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { cancellation_termination_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                                dangerouslySetInnerHTML={{ __html: first_cancellation_termination }}>
                                {/* {first_cancellation_termination} */}
                            </p>
                            <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                                <li>
                                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                        {first_first_cancellation_termination}
                                    </p>
                                </li>

                                <li>
                                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                        {first_second_cancellation_termination}
                                    </p>
                                </li>

                                <li>
                                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                        {first_third_cancellation_termination}
                                    </p>
                                </li>

                                <li>
                                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                        {first_fourth_cancellation_termination}
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_cancellation_termination}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_cancellation_termination}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_cancellation_termination}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_cancellation_termination}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { service_price_modifications_title }
                    </p>
                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_service_price_modifications}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_service_price_modifications}
                            </p>
                        </li>

                        <li>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_service_price_modifications}
                            </p>
                        </li>
                    </ol>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { author_rights_title }
                    </p>
                    <p
                        style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                        dangerouslySetInnerHTML={{ __html: author_rights }}>
                        {/* {author_rights} */}
                    </p>



                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { data_protection_and_privacy_title }
                    </p>
                    <p
                        style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}
                        dangerouslySetInnerHTML={{ __html: data_protection_and_privacy }}>
                        {/* {data_protection_and_privacy} */}
                    </p>

                    
                </div>
            </Container>
        </div>
    );
}

export default Conditions;