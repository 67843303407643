import React, { useState, useEffect } from 'react';
import { Container, Modal} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {video} from '../../../values';

const useStyles = makeStyles(theme => ({
    video:{
        width:1080,
        height:760,
        border:'none',
        marginBottom:-4,
        [theme.breakpoints.down('md')]: {
            width:980,
            height:760,
        },
        [theme.breakpoints.down('sm')]: {
            width:780,
            height:660,
        },
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            height:300,
        },
    },
}))

const Video = ({modal, parentCallback}) => {
    const classes = useStyles()
    const [size, setSize]   = useState(window.innerWidth)
    const [open, setOpen]   = useState(modal)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    const handleClose = () => {
        setOpen(false);
        parentCallback()
    };
    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <div style={{background:'black', borderRadius:10}}>
                        <iframe className={classes.video}  src={`${video}?fs=1`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Modal>
            </Container>
        </div>
    );
};

export default Video;
