import React, { useState, useEffect } from 'react';
import { Container, Toolbar, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import {Colors, 
    blog_title, 
    blog_subtitle, 
    blog_read, 
    img_logo_white, 
    blog_online, 
    contact_title, 
    blog_manage, 
    ic_facebook, 
    ic_instagram, 
    blog_comments,
    // comments, 
    blog_write, 
    blog_name, 
    blog_email, 
    blog_send,
    loading,
    blog_title_error,
    blog_subtitle_error,
} from '../../values';

import '../../fonts/font.css';
import Banner from '../global/Banner'
import Button from '../global/ButtonOutline'
import {postComment, getBlog, filterData, convertDateLocation, commentValidations} from '../../utils/BaseBuilder';
import Error404 from '../../components/global/404';
import DataNull from './DataNull';
import {useStyles} from './styles';

function Blog () {
    let {id} = useParams();
    const history = useHistory();
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const [article, setArticle] = useState('')
    const [size, setSize] = useState(window.innerWidth)
    const [dataSize, setDataSize] = useState(1)
    const [nameComment, setName]        = useState("")
    const [emailComment, setEmail]      = useState("")
    const [messageComment, setMessage]  = useState("")
    const [comments, setComments] = useState([])
    const [errorGetData, setError]           = useState(false)

    const [dataBlog, setDataBlog] = useState([])
    const [dataComment, setDataComment] = useState([])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const showArticle = (article) => {
        window.scrollTo(0, 0)
        setArticle(article)
        setShow(true)

        // asignar url
        const newUrl = `blog/${article.id}`
        history.push({pathname:newUrl})
    };
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
        getData()
    },[window])

    const filterArticle = async(res) =>{
        const response =  await filterData(res, id)
        if(response.flag === false){            
            setArticle(response.res[0])
            setShow(true)
            setComments(response.res[0].comments)
        } else{
            if(response.res.length === 0){
                setShow(true)
                setError(true)
            }
        }
    }

    const getData = async() =>{
        const res = await getBlog()
        setDataBlog(res)
        setDataSize(res.length)
        if(res.length !== 0){
            if(id){
                filterArticle(res)
            } 
        }
        if(id && res.length === 0){
            setDataSize(2)
            setShow(true)
            setError(true)
        }
    }

    const actionComment = async() =>{
        const flag = commentValidations(nameComment, emailComment, messageComment)
        if(flag){
            const res = await postComment(nameComment, emailComment, messageComment, id)
            if(res === 200){
                const obj   = {}
                const date  = new Date()

                obj.name    = nameComment
                obj.date    = date.toISOString()
                obj.message =  messageComment

                setDataComment(dataComment => [...dataComment, obj])
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        actionComment()
    }
    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner  title={errorGetData === false ? blog_title : blog_title_error} subtitle={errorGetData === false ? blog_subtitle : blog_subtitle_error}/>
                {
                    dataSize !== 0 ?
                        dataSize !== 2 ?
                            dataBlog.length !== 0 ?
                                <Grid container style={{display: show ? 'none' : ''}}>
                                    {
                                        dataBlog.map((item, index) => 
                                            <Grid item xs={12} sm={12} md={6} >
                                                <div className={classes.card}>   
                                                    <Grid container>
                                                        <Grid item xs={12} sm={6} >
                                                            <div style={{textAlign:'center'}}>
                                                                <img alt="theme" src={item.image} className={classes.image} /> 
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} >
                                                            <div style={{textAlign:'center', paddingTop:'20%'}}>
                                                                <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {item.title} </span>
                                                                <div style={{height:10}}/>
                                                                <span style={{color: Colors.gray, fontSize:18, fontFamily:'Medium'}} > {item.author} </span>
                                                                <div style={{height:10}}/>
                                                                <span style={{color: Colors.gray, fontSize:14, fontFamily:'Regular'}} > {convertDateLocation(item.date)} </span>
                                                                <div style={{height:30}}/>
                                                                <div onClick={ () => showArticle(item)}> <Button text={blog_read} color={'blue'}  onClick={ () => showArticle(item) } /> </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            :
                            <div style={{position:'relative', height:200, paddingTop:100}}>
                                <div style={{width:150, position:'absolute', left:'50%', transform:'translate(-50%, 0)'}} >
                                    <Lottie options={defaultOptions} />
                                </div>
                            </div>
                        :
                        <div />
                    :
                    <div>
                        <DataNull />
                    </div>
                }

                {/* BLOG ARTICLE */}
                {
                    errorGetData === false ?
                    <div style={{display: show ? '' : 'none', marginTop:100}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} >
                                <img alt="theme" src={article.image} className={classes.image2} /> 
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <div style={{marginTop:'20%'}}>
                                    <span style={{color: Colors.gray, fontSize:40, fontFamily:'Bold'}} > {article.title} </span>
                                    <div style={{height:10}}/>
                                    <span style={{color: Colors.gray, fontSize:18, fontFamily:'Medium'}} > {article.author} </span>
                                    <div style={{height:10}}/>
                                    <span style={{color: Colors.gray, fontSize:14, fontFamily:'Regular'}} > {convertDateLocation(article.date)} </span>
                                </div>
                            </Grid>
                        </Grid>
                        
                        <div style={{height:40}}/>
                        
                        <div style={{position:'relative'}}>
                            {/* Text 1 */}
                            <div className={classes.content3}> <span style={{color: Colors.gray, fontSize:14, fontFamily:'Regular'}} > {article.text1} </span> </div>
                            {/* Square green */}
                            <div className={classes.squareGreen}>
                                <img alt="logo" src={img_logo_white} style={{height:'auto', width:120, marginTop:40, marginLeft:52}} />
                                <div style={{marginLeft:25, marginRight:25, marginTop:30}}>  <span style={{color: Colors.white, fontSize:16, fontFamily:'Medium'}} > {blog_online} </span> </div>
                                <div style={{textAlign:'center', marginTop:30}} onClick={() => history.push('/contacto')}>
                                    <Button text={contact_title} color={'whiteGreen'} />
                                </div>
                            </div>
                        </div>

                        {/* Quotate */}
                        <div className={classes.content2}>
                            <div style={{position:'absolute', width:20, background:Colors.blue, height:'100%'}}/>
                            <div className={classes.Quotate}>  <span style={{color: Colors.gray, fontSize:24, fontFamily:'SemiBold'}} > {article.quotate} </span> </div>
                        </div>

                        {/* Text 2 */}
                        <div className={classes.content4}> <span style={{color: Colors.gray, fontSize:14, textAlign:'justify', fontFamily:'Regular'}} > {article.text2} </span> </div>

                        
                        <div className={classes.content5}>
                            <Grid container>
                                <Grid item xs={12} sm={4} >
                                    {/* Image 2 */}
                                    <img alt="theme" src={article.image2} className={classes.image3} /> 
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    {/* Text 3 */}
                                    <div style={{height:20}}/>
                                    <div style={{textAlign:'justify'}}>
                                        <span style={{color: Colors.gray, fontSize:14, fontFamily:'Regular'}} > {article.text3} </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {/* Text 4 */}
                        <div className={classes.content6}>
                            <span style={{color: Colors.gray, fontSize:14, fontFamily:'Regular'}} > {article.text4} </span>
                        </div>

                        <div style={{width:'100%', height:1, background:Colors.grayExtraLight, marginTop:50}}/>
                        <div style={{textAlign:'center'}}>
                            <div className={classes.content7}>
                                <div style={{height:10}}/>
                                <span style={{color: Colors.white, fontSize:16, fontFamily:'SemiBold'}} > {blog_manage} </span>
                            </div>
                            <div className={classes.content8}> 
                                <Grid container>
                                    <Grid item xs={12} sm={4} >
                                        <div className={classes.content14}>
                                            <img alt="logo" src={img_logo_white} className={classes.image4} /> 
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <div style={{textAlign:'center'}} onClick={() => history.push('/contacto')}>
                                            <Button text={contact_title} color={'whiteBlue'} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <div className={classes.content15}>
                                            <button className={classes.socialButton} style={{width:40, height:40, borderRadius:5, borderColor:Colors.transparent,  }}> <img alt="social" src={ic_facebook} style={{ width:12 }} />  </button>
                                            <button className={classes.socialButton} style={{width:40, height:40, borderRadius:5, borderColor:Colors.transparent,  marginLeft:10 }}> <img alt="social" src={ic_instagram} style={{ width:18 }} />  </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div style={{width:'100%', height:1, background:Colors.grayExtraLight, marginTop:50, marginBottom:50}}/>
                        <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {blog_comments} </span>

                        { comments.map((item, index) => 
                            <div className={classes.content9}> 
                                <span style={{color: Colors.gray, fontSize:20, fontFamily:'Bold'}} > {item.name} </span>
                                <div style={{height:10}}/>
                                <span style={{color: Colors.gray, fontSize:12, fontFamily:'Medium'}} > {convertDateLocation(item.date)} </span>
                                <div style={{height:10}}/>
                                <div style={{textAlign:'justify'}}>
                                    <span style={{color: Colors.gray, fontSize:16, fontFamily:'Regular'}} > {item.comment} </span>
                                </div>
                            </div>
                        )}
                        {
                            dataComment ?
                                dataComment.map((item) =>
                                    <div className={classes.content9}>
                                        <span style={{color: Colors.gray, fontSize:20, fontFamily:'Bold'}} > {item.name} </span>
                                        <div style={{height:10}}/>
                                        <span style={{color: Colors.gray, fontSize:12, fontFamily:'Medium'}} > {convertDateLocation(item.date)} </span>
                                        <div style={{height:10}}/>
                                        <div style={{textAlign:'justify'}}>
                                            <span style={{color: Colors.gray, fontSize:16, fontFamily:'Regular'}} > {item.message} </span>
                                        </div>
                                    </div>
                                )
                            :
                                <div />
                        }

                        <div style={{marginTop:100}}> <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {blog_write} </span> </div>
                        <div style={{height:20}}/>
                        <form  onSubmit={handleSubmit}>
                            <Grid container className={classes.content10}>
                                <Grid item xs={12} sm={6} >
                                    <div className={classes.content11}>
                                        <input placeholder={blog_name}  
                                            type='text' 
                                            name="nameComment"
                                            onChange={e => setName(e.target.value)}
                                            style={{marginBottom:20, borderRadius:5, textAlign:'center', width:'100%', textAlign:'left', height:40, borderWidth:0, background:Colors.grayLight, borderRadius:0, padding:5, fontFamily:'regular', fontSize:20}}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <div className={classes.content12}>
                                        <input placeholder={blog_email}  
                                            type='text' 
                                            name="emailComment"
                                            onChange={e => setEmail(e.target.value)}
                                            style={{marginBottom:20, borderRadius:5, textAlign:'center', width:'100%', textAlign:'left', height:40, borderWidth:0, background:Colors.grayLight, borderRadius:0, padding:5, fontFamily:'regular', fontSize:20}}/>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.content13}>
                                <input placeholder={blog_comments}  
                                    type='text' 
                                    name="messageComment"
                                    onChange={e => setMessage(e.target.value)}
                                    style={{marginBottom:20, borderRadius:5, textAlign:'center', width:'100%', textAlign:'left', height:160, borderWidth:0, background:Colors.grayLight, borderRadius:0, padding:5, fontFamily:'regular', fontSize:20}}/>
                            </div>
                            <Grid container style={{width:'70%'}}>
                                <Grid item xs={6} > </Grid>
                                <Grid item xs={6} >
                                    <div style={{width:'98%', marginLeft:'2%'}}>
                                        <button type='submit' className={classes.sendButton}> {blog_send} </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                :
                    <div>
                        <Error404 />
                    </div>
                }

                
                <div style={{height:100}}/>
            </Container>
        </div>
    );

}

export default Blog;