import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {Colors, img_logo_white, footer_slogan, footer_contact, phone, email, footer_copy, footer_privacy, footer_conditions, footer_use_policy, separator, youtube_kilbo_channel, facebook_kilbo_url, whatsapp} from '../../values';
import {ic_facebook, ic_youtube, ic_whats, ic_twitter, ic_instagram} from '../../values';
import '../../fonts/font.css';
import wave from '../../wave.json'
import Lottie from 'react-lottie';


const useStyles = makeStyles(theme => ({
    gradient: {
        color: '#000',
        background: 'linear-gradient(-45deg, #0085CA, #529E44)',
        backgroundSize: '400% 400%',
        animation: `$Gradient 15s ease infinite`,
    },
    '@keyframes Gradient':{
        '0%': {
            backgroundPosition: '0% 50%'
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        },
    },
    socialButton :{
        backgroundColor:'rgba(255, 255, 255, 0.2)',
        width:40, 
        height:40, 
        borderRadius:5, 
        borderColor:Colors.transparent,
        cursor:'pointer',
        '&:hover': {
            backgroundColor:'rgba(255, 255, 255, 0.5)'
        }
    },
    content:{
        width:'70%', 
        marginLeft:'30%', 
        marginTop:100,
        [theme.breakpoints.down('sm')]: {
            marginLeft:0,
        },
    }
}));


function Footer () {
    const classes = useStyles()
    const history = useHistory()
    const [size, setSize] = useState(window.innerWidth)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: wave,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    return (
        <div className={classes.gradient} style={{paddingBottom:50}}> 
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}> 
                        <div>
                            <img   src={img_logo_white} style={{ width:200, marginTop:100}} /> 
                            <div style={{height:20}}/>
                            <span style={{color: Colors.white, fontSize:18, width:300, fontFamily:'regular', textAlign:'justify'}} > {footer_slogan} </span>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <div>
                            <div className={classes.content}>
                            <span style={{color: Colors.white, fontSize:24, width:300, fontFamily:'bold', textAlign:'justify'}} > {footer_contact} </span>
                            <div style={{height:20}}/>
                            <span style={{color: Colors.white, fontSize:20, width:300, fontFamily:'regular', textAlign:'justify'}} > {phone} </span>
                            <div style={{height:10}}/>
                            <span style={{color: Colors.white, fontSize:20, width:300, fontFamily:'regular', textAlign:'justify'}} > {email} </span>
                            <div style={{height:20, width:300}}/>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <a href={facebook_kilbo_url} target="_blank">
                                    <button className={classes.socialButton}> <img   src={ic_facebook} style={{ width:12 }} />  </button>
                                </a>
                                {/* <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_instagram} style={{ width:18 }} />  </button> */}
                                <a href={youtube_kilbo_channel} target="_blank">
                                    <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_youtube} style={{ width:21 }} />  </button>
                                </a>
                                {/* <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_twitter} style={{ width:18 }} />  </button> */}
                                <a href={`https://wa.me/${whatsapp}?text=Me%20podrias%20proporcionar%20informacion%20sobre%20los%20planes%20disponibles%20por%20favor`} target="_blank">
                                    <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_whats} style={{ width:17 }} />  </button>
                                </a>
                            </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div style={{width:'100%', height:1, backgroundColor:Colors.white, marginTop:50, opacity:0.2}}/>
                <div style={{ width:'100%', textAlign:'center', marginTop:20}}> 
                    <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {footer_copy} </span> 
                    <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {separator} </span>
                    <div style={{display:'inline', cursor:'pointer'}} onClick={() => history.push( '/legal/aviso' )}> 
                            <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {footer_privacy} </span> 
                    </div>
                    <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {separator} </span>
                    <div style={{display:'inline', cursor:'pointer'}} onClick={() => history.push( '/legal/terminos' )}>
                            <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {footer_conditions} </span> 
                    </div>
                    <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {separator} </span>
                    <div style={{display:'inline', cursor:'pointer'}} onClick={() => {history.push( '/legal/aceptable' )}}>
                            <span style={{color: Colors.white, fontSize:14, fontFamily:'regular'}} > {footer_use_policy} </span> 
                    </div>
                </div>

            </Container>
        </div>
    );

}

export default Footer;