import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Toolbar, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Colors, home_intro, home_power, home_bussiness, home_manage, img_home, ic_play, start} from '../../../values';
import Button from '../../global/ButtonOutline';
import ModalForm from '../../global/ModalForm';
import Video from './Video';
import '../../../fonts/font.css';


const useStyles = makeStyles(theme => ({
    animationPlay:{
        animation: `$pulso 1s infinite`
    },
    '@keyframes pulso':{
        '0%': {transform: 'scale(0.98)',},
        '50%': {transform: 'scale(1.1)',},
        '100%': {transform: 'scale(0.98)', },
    },
    container:{
        marginTop:250,
        marginBottom:100,
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
        [theme.breakpoints.down('md')]: {
            marginTop:150,
        },
    },
    container2:{
        display:'none',
        [theme.breakpoints.down('xs')]: {
            display:'block'
        },
    },
    content1:{
        [theme.breakpoints.up('lg')]: {
            marginLeft:'5%',
            marginRight:'5%',
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft:0,
            marginRight:0,
        },
    },
    content2:{
        marginLeft:45,
        [theme.breakpoints.down('xl')]: {
            marginLeft:45,
            marginRight:0
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft:35,
            marginRight:20
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:35,
            marginRight:5
        },
    },
    content3:{
        paddingBottom:100,
        [theme.breakpoints.down('xs')]: {
            paddingBottom:10,
        },
    },
    content4:{
        cursor:'pointer', 
        width:100, 
        height:100, 
        position:'absolute', 
        left:'50%', 
        top:'50%',
        transform:'translate(-50%, -50%)',
    },
    home_power:{
        fontSize:25,
        [theme.breakpoints.down('sm')]: {
            fontSize:20,
        },
    },
    home_bussiness:{
        fontSize:80,
        [theme.breakpoints.down('sm')]: {
            fontSize:55,
        },
    }
    
}));

function Intro () {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    const [openVideo, setOpenVideo] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    const openModal = () =>{
        setOpen(true)
    }
    const callback = () =>{
        setOpen(false);
    }
    const openModal2 = () =>{
        setOpenVideo(true)
    }
    const callbackVideo = () =>{
        setOpenVideo(false)
    }
    return (
        <div>
            {
                open &&
                    <ModalForm modal={open} parentCallback={callback} />
            }
            {
                openVideo &&
                <Video modal={openVideo} parentCallback={callbackVideo} />
            }
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'} className={classes.container}>
                <div className={classes.content1}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div style={{height:600, background:Colors.blue, marginRight:5, borderRadius:'20px 0px 0px 20px', display:'flex', alignItems:'center'}}>
                                <div className={classes.content2}>
                                    <span style={{color: Colors.white, fontFamily:'Medium'}} className={classes.home_power}> {home_power} </span>
                                    <br/>
                                    <span style={{color: Colors.white, fontFamily:'Bold'}} className={classes.home_bussiness}> {home_bussiness} </span>
                                    <br/>
                                    <span style={{color: Colors.white, fontSize:16, fontFamily:'Regular'}} > {home_manage} </span>
                                    <div style={{height:60}}/>
                                    <div onClick={openModal} style={{width:140}} >
                                        <Button text={start} color='whiteBlue'/>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div style={{height:600, marginLeft:5, borderRadius:'0px 20px 20px 0px', background:Colors.grayExtraLight, position:"relative", overflow:'hidden' }}>
                                <img  src={img_home} style={{position:'absolute', height:700, width:'100%', objectFit:'cover'}} /> 
                                <div style={{width:'100%', height:700}}>
                                    <div className={classes.content4}>
                                        <img onClick={openModal2} className={classes.animationPlay} src={ic_play} /> 
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            {/* mobile */}
            <div className={classes.container2}>
                <div className={classes.content1}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div style={{height:600, background:Colors.blue, display:'flex', alignItems:'center'}}>
                                <div className={classes.content2}>
                                    <span style={{color: Colors.white, fontFamily:'Medium'}} className={classes.home_power}> {home_power} </span>
                                    <br/>
                                    <span style={{color: Colors.white, fontFamily:'Bold'}} className={classes.home_bussiness}> {home_bussiness} </span>
                                    <br/>
                                    <span style={{color: Colors.white, fontSize:16, fontFamily:'Regular'}} > {home_manage} </span>
                                    <div style={{height:60}}/>
                                    <div onClick={openModal} style={{width:140}} >
                                        <Button text='Comenzar' color='whiteBlue'/>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div style={{height:600, background:Colors.grayExtraLight, position:"relative", overflow:'hidden' }}>
                                <img  src={img_home} style={{position:'absolute', height:700, width:'100%', objectFit:'cover'}} /> 
                                <div className={classes.content4}>
                                    <img onClick={openModal2} className={classes.animationPlay} src={ic_play} /> 
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'} >
                <Grid container>
                    { home_intro.map((item, index)=> 
                        <Grid item xs={12} sm={4} key={index}>
                            <div style={{textAlign:'center'}} className={classes.content3}>
                                <div style={{width:'80%', marginLeft:'10%'}}>
                                    <div style={{height:20}}/>
                                    <p style={{color: Colors.gray, fontSize:30, fontFamily:'SemiBold'}} > {item.title} </p>
                                    <p style={{color: Colors.gray, fontSize:18, fontFamily:'Regular', textAlign:'justify'}} > {item.message} </p>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );

}

export default Intro;