import React, { useState, useEffect } from 'react';
import { Container, Modal, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import {modalValidations, addLead} from '../../utils/BaseBuilder'
import {img_logo_color, 
    title_modal, 
    subtitle_modal, 
    Colors, 
    input_modal_name, 
    input_modal_email, 
    input_modal_phone,
    button_modal, 
    select_modal, 
    select_planes, 
    ic_collapse, 
    ic_expand
} from '../../values';


const useStyles = makeStyles(theme => ({
    input:{
        marginBottom:20, 
        borderRadius:5, 
        textAlign:'center', 
        width:'100%', 
        textAlign:'left', 
        height:40, 
        borderWidth:0, 
        background:Colors.grayLight, 
        borderRadius:0, 
        padding:5, 
        fontFamily:'regular', 
        fontSize:20,
        paddingLeft:20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft:5, 
            marginLeft:-5,
        },
    },
    content:{
        paddingRight:'15%', 
        paddingLeft:'15%',
        [theme.breakpoints.down('xs')]: {
            paddingRight:0, 
            paddingLeft:0,
        },
    },
    content2:{
        display:'inline'
    },
    content3:{
        paddingRight:'15%', 
        paddingLeft:'15%',
        marginRight:17,
        [theme.breakpoints.down('xs')]: {
            paddingRight:0, 
            paddingLeft:0,
            marginRight:0,
        },
    },
    select:{
        width:'101.6%',
        padding:5,
        paddingLeft:20,
        marginBottom:20,
        // fontFamily:'regular', 
        fontSize:20,
        border:0,
        background:'#f2f8fc',
        color:'#3d86c6',
        MozAppearance:'none', 
        WebkitAppearance:'none',
        appearance:'none',
        height:40,
        backgroundImage:`url(${ic_expand})`,
        backgroundRepeat:'no-repeat, repeat',
        backgroundPosition:`right 15px top 50%, 0 0`,
        '&:focus': {
            backgroundImage:`url(${ic_collapse})`,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft:-5,
            width:'104%',
            paddingLeft:0,
        },
    },
    button:{
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.0)',
        transform: 'scale(1.0)',
        transitionDuration:'0.3s',
        width:'101.5%', 
        height:60, 
        borderRadius:5, 
        borderColor:Colors.transparent, 
        backgroundColor:Colors.blue, 
        fontFamily:'Semibold', 
        fontSize:20, 
        color:Colors.white,
        paddingLeft:18,
        [theme.breakpoints.down('xs')]: {
            marginLeft:-5,
        },
        '&:hover': {
            boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)',
            transform: 'scale(1.03)',
            transitionDuration:'0.3s',
        }
    },
}))


const ModalForm = ({modal, parentCallback, btn_plan=null}) => {
    const classes = useStyles()
    const [size, setSize]   = useState(window.innerWidth)
    const [open, setOpen]   = useState(modal)
    const [name, setName]   = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("") 
    const [plan, setPlan]   = useState(btn_plan != null ? btn_plan : "")

    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    const handleClose = () => {
        setOpen(false);
        parentCallback()
    };

    const actionContact = async()  =>{
        const res =  modalValidations(name, email, phone, plan)

        if(res){
            let selected_plan = '';
            if      ( plan == 'Plan básico' )       selected_plan = 'basic'
            else if ( plan == 'Plan profesional' )  selected_plan = 'professional'

            await addLead(name, email, phone, selected_plan)
            .then(response => {
        
                let inscribirse_route = `${window.location.origin}/inscribirse?plan=${selected_plan}&name=${name.replace(/ /gi, '%20')}&email=${email}`;
    
                window.open(inscribirse_route, '_self');
            })
            .catch( error => {
                console.log(error);
            });
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        actionContact()
    }

    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{display:'flex', alignItems:'center', justifyContent:'center', MozOutline:'none'}}
                >
                    <Container maxWidth="md">
                        <div style={{background:'white', borderRadius:10}}>
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <div style={{paddingTop:50, paddingBottom:50}}>
                                        <img src={img_logo_color} />
                                        
                                        <div style={{paddingTop:30}}><span style={{fontWeight:700, fontSize:25}}> {title_modal} </span></div>
                                        <div><span style={{fontSize:16}}> {subtitle_modal} </span></div>
                                        <div style={{paddingTop:20}} />

                                        <div className={classes.content}>
                                            <select className={classes.select} defaultValue={plan != '' ? plan : 'DEFAULT'} onChange={e => setPlan(e.target.value)}>
                                                <option disabled hidden value={"DEFAULT"}> {select_modal} </option>
                                                {
                                                    select_planes.map((item) =>
                                                        <option value={item.text} key={item.text}> {item.text} </option>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className={classes.content3}>
                                            <input placeholder={input_modal_name}  
                                                type='text' 
                                                name="name"
                                                className={classes.input}
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>

                                        <div className={classes.content3}>
                                            <input placeholder={input_modal_email}  
                                                type='text' 
                                                name="email"
                                                className={classes.input}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className={classes.content3}>
                                            <input placeholder={input_modal_phone}  
                                                type='text' 
                                                name="phone"
                                                className={classes.input}
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className={classes.content}>
                                            <button className={classes.button} style={{cursor:'pointer'}} type='submit' >
                                                <Grid container style={{paddingTop:10}}>
                                                    <Grid item xs={6} style={{textAlign:'left'}}>
                                                        {button_modal}
                                                    </Grid>
                                                    <Grid item xs={6} style={{textAlign:'right', paddingRight:5}}>
                                                        <SendIcon />
                                                    </Grid>
                                                </Grid>
                                                
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Container>
                        </div>
                    </Container>
                </Modal>
            </Container>
        </div>
    );
};

export default ModalForm;