import React, { useState } from 'react';
import NavBar from './Navbar';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import {menuIcon} from '../../values';
import { Button, Toolbar, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    heightMenu: { height:50, [theme.breakpoints.down('xs')]: { height:100, }, },
    heightMenu2:{ height: 50, [theme.breakpoints.between('md', 'lg')]: { height:1000}, [theme.breakpoints.up('xl')]: {height:'80vh'}, [theme.breakpoints.down('xs')]: {height:1000}}
}));

function Menu(props) {
    const [size, setSize] = useState(50)
    const classes = useStyles()

    // React.useEffect(() => console.log(props), []);

        return (
            <div style = {{position:'fixed', top:0, width:'100%', zIndex:4}}>
                <div >
                    <NavBar history={props.history}/>
                </div>
            </div>
        );
}


export default Menu;