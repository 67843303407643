import React, { useState, useEffect } from 'react';
import { Container, Toolbar, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {Colors,img_dots, 
    img_bussiness, img_about, 
    img_about_phone,about_title, 
    about_subtitle, 
    about_description, 
    about_vision, 
    about_mission, 
    about_vision_message, 
    about_mission_message, 
    about_expand, 
    about_expand_description,
    start
} from '../../values';

import '../../fonts/font.css';
import Button from '../global/ButtonOutline'
import Bannner from '../global/BannerPicture'
import ModalForm from '../global/ModalForm'


const useStyles = makeStyles(theme => ({
    about_mission:{
        marginLeft:'10%',
        width:'70%',
        [theme.breakpoints.down('xs')]: {
            marginLeft:0,
            width:'100%'
        },
    },
    about_vision:{
        marginLeft:'20%',
        width:'70%',
        [theme.breakpoints.down('xs')]: {
            marginLeft:0,
            width:'100%'
        },
    },
    content1:{
        marginTop:50, 
        width:'90%', 
        marginLeft:'5%',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            marginLeft:0,
        },
    },
    content2:{
        width:'90%',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
        },
    },
    about_expand:{
        fontSize:60, 
        [theme.breakpoints.down('xs')]: {
            fontSize:30, 
        },
    },
    about_expand_description:{
        fontSize:18,
        [theme.breakpoints.down('xs')]: {
            fontSize:16, 
        },
    }
}))

function About () {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    const openModal = () =>{
        console.log("OK")
        setOpen(true)
    }
    const callback = () =>{
        setOpen(false);
    }
    return (
        <div>
            {
                open &&
                    <ModalForm modal={open} parentCallback={callback} />
            }
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Bannner image={img_about} imagePhone={img_about_phone} title={about_title} subtitle={about_subtitle}/>
                <div className={classes.content1}>
                    <span style={{color: Colors.gray, fontSize:18, width:'70%', fontFamily:'Regular', textAlign:'justify'}} > {about_description} </span>
                </div>
                <div style={{height:70}}/>

                <Grid container>
                    <Grid item xs={12} sm={6}> 
                        <div style={{marginTop:50}} className={classes.about_mission}>
                            <span style={{color: Colors.gray, fontSize:18, width:'70%', fontFamily:'Bold', textAlign:'justify'}} > {about_mission} </span>
                            <div style={{height:20}}/>
                            <span style={{color: Colors.gray, fontSize:18, width:'70%', fontFamily:'Regular', textAlign:'justify'}} > {about_mission_message} </span>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div style={{marginTop:50}} className={classes.about_vision}>
                            <span style={{color: Colors.gray, fontSize:18, width:'70%', fontFamily:'Bold', textAlign:'justify'}} > {about_vision} </span>
                            <div style={{height:20}}/>
                            <span style={{color: Colors.gray, fontSize:18, width:'70%', fontFamily:'Regular', textAlign:'justify'}} > {about_vision_message} </span>
                        </div>
                    </Grid>
                </Grid>

                <div style={{height:100}}/>

                <Grid container>
                    <Grid item xs={12} sm={6}> 
                        <div className={classes.content2}>
                            <img   src={img_dots} style={{width:'80%'}} /> 
                            <img   src={img_bussiness} style={{ marginLeft:'20%', marginTop:'-80%', width:'80%', borderRadius:30}} /> 
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div style={{width:'100%'}}> 
                            <div style={{marginTop:'30%'}}>
                                <span style={{color: Colors.gray, fontSize:20, fontFamily:'Medium'}} > {about_expand} </span>
                                <br/>
                                <span style={{color: Colors.gray, fontFamily:'Bold'}} className={classes.about_expand} > {about_expand} </span>
                                <br/>
                                <span style={{color: Colors.gray, fontFamily:'Regular'}} className={classes.about_expand_description} > {about_expand_description} </span>
                                <div style={{height:20}}/>
                                <div onClick={openModal} style={{width:140}} >
                                    <Button text={start} color='blue'/>
                                </div>
                        </div>
                        </div>
                    </Grid>
                </Grid>

            </Container>
            <div style={{height:200}}/>
        </div>
    );

}

export default About;