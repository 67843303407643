import React, { useState, useEffect } from 'react';
import { Container, Toolbar, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import '../../fonts/font.css';
import {Colors, img_pricing, img_pricing_a,img_pricing_phone, img_pricing_b, pricing_title, pricing_subtitle, start} from '../../values';
import {basic_pricing_starter, basic_pricing_starter_price, basic_pricing_starter_options, pro_pricing_starter, pro_pricing_starter_price, pro_pricing_starter_options, aditional_user, aditional_user_cost} from '../../values';
import Button from '../global/ButtonOutline'
import Subscribe from '../global/Subscribe'
import Bannner from '../global/BannerPicture'
import ModalForm from '../global/ModalForm'


const useStyles = makeStyles(theme => ({
    content1:{
        marginTop:50, 
        width:'90%', 
        marginLeft:'5%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
            marginLeft:0,
        },
    },
    content2:{
        width:'100%', 
        textAlign:'right',
        [theme.breakpoints.down('xs')]: {
            textAlign:'left'
        },
    },
    content3:{
        width:140, 
        marginLeft:'39%',
        [theme.breakpoints.down('md')]: {
            marginLeft:'35%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:'40%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft:'30%',
        },
    },
}))

function Pricing () {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)
    const [btn_plan, setBtnPlan] = useState('')
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    const openModal = (plan) =>{
        console.log("OK")
        setBtnPlan(plan);
        setOpen(true)
    }
    const callback = () =>{
        setOpen(false);
    }
    return (
        <div>
            {
                open &&
                    <ModalForm modal={open} parentCallback={callback} btn_plan={btn_plan}/>
            }
            <Container  maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Bannner image={img_pricing} imagePhone={img_pricing_phone} title={pricing_title} subtitle={pricing_subtitle}/>
                <Grid container>
                    <Grid item  xs={12} sm={12} md={6} style={{marginBottom:100}}>
                        <div className={classes.content1} style={{borderRadius:20, boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)', overflow:'hidden', textAlign:'center'}}>   
                            <img  src={img_pricing_a} style={{height:200, marginTop:20}} /> 
                            <Grid container style={{width:'80%', marginLeft:'10%', marginTop:30}}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{width:'100%', textAlign:'left'}}>
                                        <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {basic_pricing_starter} </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <div className={classes.content2}>
                                        <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {basic_pricing_starter_price} </span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container style={{width:'80%', marginLeft:'10%'}}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{width:'100%', textAlign:'left'}}>
                                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Bold'}} > {aditional_user} </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <div className={classes.content2}>
                                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Bold'}} > {aditional_user_cost} </span>
                                    </div>
                                </Grid>
                            </Grid>
                            <div style={{width:'90%', height:1, backgroundColor:Colors.grayLight, marginTop:20, marginBottom:20}}/>
                            { basic_pricing_starter_options.map((item, index) => 
                                <div style={{width:'80%', marginBottom:20, textAlign:'left', marginLeft:'10%'}}>
                                    <span style={{color: Colors.gray, fontSize:20, fontFamily:'Regular'}} > {item} </span>
                                </div>
                            )}
                            <div style={{backgroundColor:Colors.green, height:100}} >
                                <div style={{height:20}}/>
                                <div style={{width:'100%'}}>
                                    <div onClick={() => openModal('Plan básico')} className={classes.content3}>
                                        <Button text={start} color='whiteGreen'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} >
                        <div className={classes.content1}  style={{borderRadius:20, boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)', overflow:'hidden', textAlign:'center'}}>   
                            <img  src={img_pricing_b} style={{height:200, marginTop:20}} /> 
                            <Grid container style={{width:'80%', marginLeft:'10%', marginTop:30}}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{width:'100%', textAlign:'left'}}>
                                        <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {pro_pricing_starter} </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.content2}>
                                        <span style={{color: Colors.gray, fontSize:30, fontFamily:'Bold'}} > {pro_pricing_starter_price} </span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container style={{width:'80%', marginLeft:'10%'}}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{width:'100%', textAlign:'left'}}>
                                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Bold'}} > {aditional_user} </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <div className={classes.content2}>
                                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Bold'}} > {aditional_user_cost} </span>
                                    </div>
                                </Grid>
                            </Grid>
                            <div style={{width:'90%', height:1, backgroundColor:Colors.grayLight, marginTop:20, marginBottom:20}}/>
                            { pro_pricing_starter_options.map((item, index) => 
                                <div style={{width:'80%', marginBottom:20, textAlign:'left', marginLeft:'10%'}}>
                                    <span style={{color: Colors.gray, fontSize:20, fontFamily:'Regular'}} > {item} </span>
                                </div>
                            )}
                            <div style={{backgroundColor:Colors.blue, height:100}}>
                                <div style={{height:20}}/>
                                <div onClick={() => openModal('Plan profesional')} className={classes.content3}>
                                    <Button text={start} color='whiteBlue'/>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Subscribe />
            </Container>
        </div>
    );

}

export default Pricing;