import React from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {dataNull, inicio, blog_data_null, Colors} from '../../values';
import Button from '../global/ButtonOutline';

const useStyles = makeStyles(theme => ({
    lottie:{
        width:300, 
        position:'absolute', 
        left:'50%', 
        transform:'translate(-50%, 0)',
        [theme.breakpoints.down('xs')]: {
            width:'90%', 
        },
    },
    content:{
        position:'relative',
        height:260, 
        [theme.breakpoints.down('xs')]: {
            height:300, 
        },
    },
    content2:{
        width:400,
        justifyContent:'center',
        [theme.breakpoints.down('xs')]: {
            width:'auto' 
        },
    },
    text:{
        fontWeight:600,
        fontSize:25,
        color:Colors.grayDark
    }
}))


const DataNull = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dataNull,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const classes = useStyles()
    const history = useHistory()
    return (
        <div>
            <div className={classes.content}>
                <div className={classes.lottie} >
                    <Lottie options={defaultOptions} />
                </div>
            </div>
            <div style={{textAlign:'center', justifyContent:'center', display:'flex'}}>
                <div className={classes.content2}>
                    <p className={classes.text}> {blog_data_null} </p>
                </div>
            </div>
            <div style={{textAlign:'center'}} onClick={() => history.push('/')}>
                <Button text={inicio}  color={'blue'} />
            </div>
        </div>
    );
};

export default DataNull;