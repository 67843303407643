import React, { useState } from 'react';
import Intro from './components/Intro';
import Features from './components/Features';
import Platform from './components/Platform';
import Mobile from './components/Mobile';
import Subscribe from '../global/Subscribe';
import Footer from '../global/Footer'
import { Container, Toolbar, Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {Colors} from '../../values';
import '../../fonts/font.css';


function Home () {
    return (
        <div>
            <div style={{position: 'relative'}}> <Intro/>  </div>
            <div style={{position: 'relative'}}> <Features/>  </div>
            <div style={{position: 'relative'}}> <Platform/>  </div>
            <div style={{position: 'relative'}}> <Mobile/>  </div>
            <div style={{position: 'relative'}}> <Subscribe/>  </div>
        </div>
    );

}

export default Home;