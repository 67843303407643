import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import "./index.css"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Menu from "./components/global/Menu"
import Home from '../src/components/home/Home';
import About from './components/about/About';
import Pricing from './components/pricing/Pricing';
import Blog from './components/blog/Blog';
import Questions from './components/questions/Questions';
import Contact from './components/contact/Contact';
import Footer from './components/global/Footer';
import Privacy from './components/privacy/Privacy';
import Conditions from './components/conditions/Conditions';
import UsagePolicies from './components/usepolicies/UsegePolicies';
import ScrollToTop from './components/global/ScrollToTop';
import SignOn from './components/signon/SignOn';
import SuccessfulSignOn from './components/signon/SuccessfulSignOn';


function App (props) {

  return (
    <div>   
      <ToastContainer />   
      <Router>
        <ScrollToTop>
          <Menu size = {100} {...props}/>
          <Route exact path="/"               component={Home}      />
          <Route exact path="/inicio"         component={Home}      />
          <Route exact path="/nosotros"       component={About}     />
          <Route exact path="/precios"        component={Pricing}   />
          <Route exact path="/blog"           component={Blog}      />
          <Route exact path="/blog/:id"       component={Blog}      />
          <Route exact path="/preguntas"      component={Questions} />
          <Route exact path="/contacto"       component={Contact}   />
          <Route exact path="/legal/aviso"      component={Privacy}   />
          <Route exact path="/legal/terminos"   component={Conditions}   />
          <Route exact path="/legal/aceptable"  component={UsagePolicies}    />
          <Route exact path="/inscribirse"    component={SignOn}    />
          <Route exact path="/registro_exitoso"    component={SuccessfulSignOn}    />
          <Footer/>
        </ScrollToTop>
      </Router>
      
    </div>
    );
}

export default App;
