import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Colors} from '../../values';
import '../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    title:{
        fontSize:70, 
        color: Colors.white,
        [theme.breakpoints.down('xs')]: {
            fontSize:40, 
        },
    },
    content:{
        marginLeft:100,
        [theme.breakpoints.down('xs')]: {
            marginLeft:20,
        },
    }
}))


function Banner ({title, subtitle}) {
    const classes = useStyles()
    return (
        <div>
            <div style={{height:200, borderRadius:20, background:'linear-gradient(90deg, rgba(0,133,202,1) 0%, rgba(0,133,202,1) 50%, rgba(82,158,68,1) 100%)', position:"relative", overflow:'hidden', marginTop:200 }}>
                <div style={{width:'100%', height:200, display:'flex', alignItems:'center', position:'absolute'}}>
                    <div className={classes.content}>
                        <span style={{color: Colors.white, fontSize:20, fontFamily:'SemiBold'}} > {subtitle} </span>
                        <br/>
                        <span style={{ fontFamily:'Bold'}} className={classes.title}> {title} </span>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Banner;