import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Toolbar, Grid, Button } from '@material-ui/core';
import {Colors, start} from '../../values';
import '../../fonts/font.css';
import './ButtonOutline.css'


function ButtonOutline ({color, text}) {
    return (
        <div>
            <button 
                className={ 
                    color == 'blue' ? 'buttonOutlineBlue' :
                    color == 'green' ? 'buttonOutlineGreen' :
                    color == 'whiteGreen' ? 'buttonOutlineWhiteGreen' : 'buttonOutlineWhiteBlue'
                }
            > {text} 
            </button>   
        </div>
    );

}

export default ButtonOutline;