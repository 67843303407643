import React, {useState, useEffect} from 'react';
import { Container } from '@material-ui/core';
import {
    subtitle_usage_policy,
    title_usage_policy,
    usage_policies_opening,
    usage_policies_ending,
    title_first_usage_policy,
    first_usage_policy,
    title_second_usage_policy,
    second_usage_policy,
    title_third_usage_policy,
    third_usage_policy,
    title_fourth_usage_policy,
    fourth_usage_policy,
    title_fifth_usage_policy,
    fifth_usage_policy,
    title_sixth_usage_policy,
    sixth_usage_policy,
    title_seventh_usage_policy,
    seventh_usage_policy,
    title_eighth_usage_policy,
    eighth_usage_policy,
    title_nineth_usage_policy,
    nineth_usage_policy,
    title_tenth_usage_policy,
    tenth_usage_policy,
    title_eleventh_usage_policy,
    eleventh_usage_policy,
    title_twelfth_usage_policy,
    twelfth_usage_policy,
} from '../../values';
import Banner from '../global/Banner'


function UsagePolicies(props) {
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div style={{minHeight:'80vh'}}>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner  title={title_usage_policy} subtitle={subtitle_usage_policy}/>
                <div style={{padding:'80px 50px 50px 50px'}}>

                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                        {usage_policies_opening}
                    </p>

                    <ol style={{paddingTop:'20px', paddingBottom:'20px'}}>
                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_first_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {first_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_second_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {second_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_third_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {third_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_fourth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fourth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_fifth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {fifth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_sixth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {sixth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_seventh_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {seventh_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_eighth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {eighth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_nineth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {nineth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_tenth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {tenth_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_eleventh_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {eleventh_usage_policy}
                            </p>
                        </li>

                        <li>
                            <h3 style={{fontFamily:'Regular'}}>{ title_twelfth_usage_policy }</h3>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                                {twelfth_usage_policy}
                            </p>
                        </li>
                    </ol>


                    <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular'}}>
                        {usage_policies_ending}
                    </p>
                </div>
            </Container>
        </div>
    );
}

export default UsagePolicies;