import React from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {error, inicio} from '../../values';
import Button from '../global/ButtonOutline'


const useStyles = makeStyles(theme => ({
    lottie:{
        width:550, 
        position:'absolute', 
        left:'50%', 
        transform:'translate(-50%, 0)',
        [theme.breakpoints.up('xl')]: {
            width:750, 
        },
        [theme.breakpoints.down('xs')]: {
            width:'90%', 
        },
    },
    content:{
        position:'relative',
        height:550, 
        paddingTop:100,
        [theme.breakpoints.down('lg')]: {
            height:400, 
        },

        [theme.breakpoints.down('xs')]: {
            height:250, 
        },
    }
}))


function Error(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: error,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const classes = useStyles()
    const history = useHistory();
    return (
        <div>
            <div className={classes.content}>
                <div className={classes.lottie} >
                    <Lottie options={defaultOptions} />
                </div>
            </div>
            <div style={{textAlign:'center'}} onClick={() => history.push('/')}>
                <Button text={inicio}  color={'blue'} />
            </div>
        </div>
    );
};

export default Error;