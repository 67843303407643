import { makeStyles } from '@material-ui/core/styles';
import {Colors} from '../../values';



export const useStyles = makeStyles(theme => ({
    socialButton :{
        backgroundColor:'rgba(255, 255, 255, 0.2)',
        '&:hover': {
            backgroundColor:'rgba(255, 255, 255, 0.5)'
        }
    },
    sendButton :{
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.0)',
        transform: 'scale(1.0)',
        transitionDuration:'0.3s',
        width:'100%', 
        height:60, 
        borderRadius:5, 
        borderColor:Colors.transparent, 
        backgroundColor:Colors.blue, 
        fontFamily:'Semibold', 
        fontSize:20, 
        color:Colors.white,
        cursor:'pointer',
        '&:hover': {
            boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)',
            transform: 'scale(1.03)',
            transitionDuration:'0.3s'
        }
    },
    image:{
        objectFit:'cover', 
        height:200, 
        width:200, 
        marginLeft:30, 
        marginTop:30, 
        borderRadius:125,
        [theme.breakpoints.down('md')]: {
            height:150, 
            width:150,
            marginLeft:0, 
        },
        [theme.breakpoints.down('sm')]: {
            height:250, 
            width:250,
        },
    },
    image2:{
        height:'auto', 
        width:'90%', 
        marginTop:30, 
        borderRadius:15,
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
        },
    },
    image3:{
        height:'auto', 
        width:'90%', 
        marginTop:30, 
        borderRadius:5,
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    image4:{
        height:'auto', 
        width:110, 
        marginLeft:30,
        [theme.breakpoints.down('xs')]: {
            marginLeft:0,
        },
    },
    card:{
        width:'90%', 
        marginLeft:'5%', 
        marginTop:100, 
        borderRadius:20, 
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)', 
        overflow:'hidden', 
        paddingBottom:30,
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
            marginLeft:0,
        },
    },
    content1:{
        marginTop:'20%',
        [theme.breakpoints.down('xs')]: {
            textAlign:'center'
        },
    },
    content2:{
        width:'40%', 
        position:'relative', 
        height:150, 
        marginTop:50,
        [theme.breakpoints.down('md')]: {
            width:'60%', 
        },
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content3:{
        width:'70%', 
        position:'relative',
        textAlign:'justify',
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content4:{
        width:'70%', 
        position:'relative', 
        marginTop:50,
        textAlign:'justify',
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content5:{
        position:'relative', 
        marginTop:50, 
        width:'70%',
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content6:{
        position:'relative',
        marginTop:50, 
        width:'70%',
        textAlign:'justify',
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content7:{
        background:Colors.blueDark, 
        height:'auto', 
        width:'70%', 
        marginLeft:'15%', 
        marginTop:50,
        paddingBottom:10,
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
            marginLeft:0,  
        },
    },
    content8:{
        background:Colors.blue, 
        height:'auto', 
        width:'70%', 
        marginLeft:'15%', 
        marginTop:10, 
        display:'flex', 
        alignItems:'center',
        paddingTop:10,
        paddingBottom:10,
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
            marginLeft:0,
        },
    },
    content9:{
        marginTop:30, 
        width:'70%',
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
        },
    },
    content10:{
        width:'70%',
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
        },
    },
    content11:{
        width:'98%',
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
        },
    },
    content12:{
        width:'98%', 
        marginLeft:'2%',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            marginLeft:0,
        },
    },
    content13:{
        width:'70%', 
        marginTop:3,
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
    content14:{
        textAlign:'left',
        [theme.breakpoints.down('xs')]: {
            textAlign:'center',
            paddingBottom:10,
        },
    },
    content15:{
        textAlign:'rigth',
        paddingRight:30,
        [theme.breakpoints.down('xs')]: {
            textAlign:'center',
            paddingTop:10,
            paddingRight:0,
        },
    },
    Quotate:{
        position:'absolute', 
        left:40, 
        top:20,
        [theme.breakpoints.down('md')]: {
            top:20,
        },
        [theme.breakpoints.down('xs')]: {
            top:5,
        },
    },
    squareGreen:{
        right:0, 
        position:'absolute', 
        width:218, 
        height:300, 
        background:Colors.green, 
        borderRadius:5,
        [theme.breakpoints.down('xs')]: {
            display:'none',
        },
    },
    
}));
