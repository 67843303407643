import React, { useState, useEffect } from 'react';
import { Container, Toolbar, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import {Colors, img_contact, contact_title, img_contact_phone, contact_subtitle, contact_find, phone, email, contact_name, contact_email, contact_message, contact_phone, contact_send, youtube_kilbo_channel, facebook_kilbo_url, whatsapp} from '../../values';
import {ic_youtube, ic_instagram, ic_whats, ic_twitter, ic_facebook, ic_marker} from '../../values';
import '../../fonts/font.css';
import Banner from '../global/BannerPicture'
import GoogleMapReact from 'google-map-react';
import {postContact, contactValidations} from '../../utils/BaseBuilder';

const useStyles = makeStyles(theme => ({
    socialButton :{
        backgroundColor:'rgba(0, 133, 202, 1.0)',
        width:40, 
        height:40, 
        borderRadius:5, 
        borderColor:Colors.transparent,
        cursor:'pointer',
        '&:hover': {
            backgroundColor:'rgba(0, 133, 202, 0.5)'
        }
    },
    input:{
        marginBottom:20, 
        borderRadius:5, 
        textAlign:'center', 
        width:'100%', 
        textAlign:'left', 
        height:40, 
        borderWidth:0, 
        background:Colors.grayLight, 
        borderRadius:0, 
        padding:5, 
        fontFamily:'regular', 
        fontSize:20
    },
    sendButton :{
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.0)',
        transform: 'scale(1.0)',
        transitionDuration:'0.3s',
        width:'100%', 
        height:60, 
        borderRadius:5, 
        borderColor:Colors.transparent, 
        backgroundColor:Colors.blue, 
        fontFamily:'Semibold', 
        fontSize:20, 
        color:Colors.white,
        cursor:'pointer',
        '&:hover': {
            boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)',
            transform: 'scale(1.03)',
            transitionDuration:'0.3s'
        }
    },
    content:{
        width:'70%',
        [theme.breakpoints.down('md')]: {
            width:'80%',
        },
        [theme.breakpoints.down('sm')]: {
            width:'98%',
        },
    },
    content2:{
        width:'71.5%',
        marginBottom:100,
        [theme.breakpoints.down('md')]: {
            width:'81.1%',
        },
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.down('xs')]: {
            width:'100%',
        },
    }
}));

function Contact () {

    const classes = useStyles()

    const [nameContact, setName]       = useState("")
    const [emailContact, setEmail]     = useState("")
    const [phoneContact, setPhone]     = useState("")
    const [messageContact, setMessage] = useState("")

    const options = {
        center: {
        lat: 20.732693,
        lng: -103.456087
        },
        zoom: 18
    }; 

    const Marker = () => <div><img style={{width:'75px', height:'auto', transform:'translate(-50%, -100%)'}} src={ic_marker} /></div>;

    const MAP_OPTIONS = { scrollwheel: false }
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    const actionContact = async() =>{
        const res = contactValidations(nameContact, emailContact, phoneContact, messageContact);
        if(res){
            await postContact(nameContact, emailContact, phoneContact, messageContact)
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        actionContact()
    }
    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner image={img_contact} imagePhone={img_contact_phone} title={contact_title} subtitle={contact_subtitle}/>
                <div style={{paddingBottom:50}}/>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.content}>   
                                <input placeholder={contact_name}    
                                    type='text' 
                                    name="nameContact"
                                    onChange={e => setName(e.target.value)}
                                    className={classes.input}
                                />
                                <input placeholder={contact_email}   
                                    type='text' 
                                    name="emailContact"
                                    onChange={e => setEmail(e.target.value)}
                                    className={classes.input}
                                />
                                <input placeholder={contact_phone}
                                    type='text' 
                                    name="phoneContact"
                                    onChange={e => setPhone(e.target.value)}
                                    className={classes.input}
                                    />
                                <input placeholder={contact_message} 
                                    type='text' 
                                    name="messageContact"
                                    onChange={e => setMessage(e.target.value)}
                                    style={{height:160}}
                                    className={classes.input}
                                />
                            </div>
                            <div className={classes.content2}>
                                <button type='submit' className={classes.sendButton}> {contact_send} </button>
                            </div>
                        </form>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{textAlign:'center'}}>   
                            <div style={{borderRadius:20, height:400, boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)', textAlign:'left'}}>
                                <div style={{height:60}}/>
                                <div style={{marginLeft:30}}>
                                    <span style={{ color: Colors.gray, fontSize:40, fontFamily:'Bold'}} > {contact_find} </span>
                                </div>
                                <div style={{height:40}}/>
                                <span style={{marginLeft:40, color: Colors.gray, fontSize:25, fontFamily:'Regular'}} > {email} </span>
                                <div style={{height:20}}/>
                                <span style={{marginLeft:40, color: Colors.gray, fontSize:25, fontFamily:'Regular'}} > {phone} </span>
                                <div style={{height:60}}/>
                                <div style={{width:'100%', textAlign:'center'}}>
                                    <div style={{marginLeft:40,display:'flex', alignItems:'center'}}>
                                        <a href={facebook_kilbo_url} target="_blank">
                                            <button className={classes.socialButton}> <img   src={ic_facebook} style={{ width:12 }} />  </button>
                                        </a>
                                        {/* <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_instagram} style={{ width:18 }} />  </button> */}
                                        <a href={youtube_kilbo_channel} target="_blank">
                                            <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_youtube} style={{ width:21 }} />  </button>
                                        </a>
                                        {/* <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_twitter} style={{ width:18 }} />  </button> */}
                                        <a href={`https://wa.me/${whatsapp}?text=Me%20podrias%20proporcionar%20informacion%20sobre%20los%20planes%20disponibles%20por%20favor`} target="_blank">
                                            <button className={classes.socialButton} style={{marginLeft:10 }}> <img   src={ic_whats} style={{ width:17 }} />  </button>
                                        </a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div style={{width:'100%', height:400, marginTop:100}}>
                        {
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyD2M12E5T6mlidtLuoJCfkzuuG_iIdsNFs' }}
                            tracksViewChanges={false}
                            defaultCenter={options.center}
                            defaultZoom={options.zoom}
                            options = {MAP_OPTIONS}>
                                <Marker
                                    lat={20.732693}
                                    lng={-103.456087}/>
                            </GoogleMapReact>
                        }
            </div>

            </Container>
            <div style={{height:200}}/>
        </div>
    );

}

export default Contact;