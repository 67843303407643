import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container, Grid, CircularProgress, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import {
	Colors,
	start,
	form_shop_name,
	form_shop_plan,
	form_shop_users,
	form_shop_branch,
	form_shop_username,
	form_shop_password,
	form_shop_password_confirmation,
	form_shop_email,
	form_shop_user_name,
	form_shop_user_lname,
	api_zip_codes
} from '../../values';
import '../../fonts/font.css';
import {
	kilboSignOn,
	signOnDataValidation,
	updateLead,
	brand_name_regex,
	username_regex,
	password_regex,
	email_regex,
	name_regex,
	branch_name_regex
} from '../../utils/BaseBuilder';

const useStyles = makeStyles(theme => ({
    subtitle:{
        minWidth: '100%',
    },
    form:{
        display: 'flex',
        flexWrap: 'wrap',
    },
    input_container:{
        minWidth: '30%',
        maxWidth: '30%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '45%',
            maxWidth: '45%',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '95%',
            maxWidth: '95%',
        },
    },
    input:{
        marginBottom: 10,
        borderRadius: 10, 
        textAlign:'center', 
        height:30, 
        borderWidth:0, 
        background:Colors.grayLight,
        padding:5, 
        fontFamily:'regular', 
        fontSize:16,
        minWidth: '95%',
    },
    sendButton :{
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.0)',
        transform: 'scale(1.0)',
        transitionDuration:'0.3s',
        width:'35%', 
        height:60, 
        borderRadius:5, 
        borderColor:Colors.transparent, 
        backgroundColor:Colors.blue, 
        fontFamily:'Semibold', 
        fontSize:20, 
        color:Colors.white,
        cursor:'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)',
            transform: 'scale(1.03)',
            transitionDuration:'0.3s'
        }
    },
    content:{
        display: 'flex',
        minWidth: '100%',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        
    },
    content2:{
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    login_info:{
        minWidth: '30%',
        [theme.breakpoints.down('xs')]: {
            minWidth: '95%',
        },
    },
    warn_text:{
        textAlign: 'center',
        color: '#999900',
    },
    display_none:{
        display: 'none',
    },
}));

function SignOn () {
    const classes = useStyles()
    let plan_from_url = null;
    let name_from_url = null;
    let lastname_from_url = null;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('plan')) {
        let plan = urlParams.get('plan');
        if (plan === 'professional' || plan === 'basic') {
            plan_from_url = plan;
        }
    }

    if (urlParams.has('name')) {
        let full_name = urlParams.get('name');
        let names = full_name.split(' ');
        
        // SUPOSICIONES
        if (names.length === 1) { // Solo mando un nombre
            name_from_url = `${names[0]}`;
        } else if (names.length === 2) { // Mando un nombre y un apellido
            name_from_url = `${names[0]}`;
            lastname_from_url = `${names[1]}`;
        } else if (names.length === 3) { // Mando un nombre y dos apellidos
            name_from_url = `${names[0]}`;
            lastname_from_url = `${names[1]} ${names[2]}`;
        } else if (names.length === 4) { // Mando dos nombres y dos apellidos
            name_from_url = `${names[0]} ${names[1]}`;
            lastname_from_url = `${names[2]} ${names[3]}`;
        }
    }

    const [shopName, setShopName]               = useState("")
    const [plan, setPlan]                       = useState(plan_from_url != null ? plan_from_url : "basic")
    const [amountUsers, setAmountUsers]         = useState(plan_from_url != null && plan_from_url === 'professional' ? 4 : 2)

    const [username, setUsermane]               = useState("")
    const [password, setPassword]               = useState("")
    const [passwordC, setPasswordC]             = useState("")
    const [email, setEmail]                     = useState(urlParams.has('email') ? urlParams.get('email') : '')
    const [name, setName]                       = useState(name_from_url != null ? name_from_url : "")
    const [lastname, setLastname]               = useState(lastname_from_url != null ? lastname_from_url : "")

    const [branchName, setBranchName]           = useState("matriz")
    const [branchStreet, setBranchStreet]       = useState("")
    const [branchExtNumber, setBranchExtNumber] = useState(0)
    const [branchIntNumber, setBranchIntNumber] = useState(0)
    const [branchZipCode, setBranchZipCode]     = useState("")
    const [branchColonies, setBranchColonies]   = useState([])
    const [branchColony, setBranchColony]       = useState("")
    const [branchCity, setBranchCity]           = useState("")
    const [branchState, setBranchState]         = useState("")
    const [branchCountry, setBranchCountry]     = useState("")

    const [planCost, setPlanCost]               = useState(plan === 'professional' ? 799 : 299)

    const [sending, setSendingData]             = useState(false)

    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

	const actionContact = async() => {

		const new_store_data = {
			newUSerToken: 'esNuevo',
			kilboUser: shopName.toLowerCase().replace(/ /gi, ''),
			brandName: shopName,
			rfc: '',
			taxLegend: null,
			showTax: 0,
			taxType: 0,
			ticketSize: 0,
			plan: plan,
			// plan_base_cost: 0,           Estas propiedades pueden ser usadas para indicar el precio base del plan y el costo por usuario adicional,
			// additional_user_cost: 0,     si no son enviadas, se toman los costos default, precios base 299 (basico) y 799 (profesional), y 150 usuario extra
			usersAllowed: amountUsers,
			username: username,
			userPassword: password,
			userPassword_confirmation: passwordC,
			email: email,
			name: name,
			lname: lastname,
			gender: null,
			birthdate: null,
			user_address: false,
			shopName: branchName,
			shopStreet: branchStreet,
			shopExtNumber: branchExtNumber,
			shopIntNumber: branchIntNumber,
			shopZip: branchZipCode,
			shopNeighborhood: branchColony,
			shopCity: branchCity,
			shopState: branchState,
			shopCountry: branchCountry,
		};

		const is_data_valid = signOnDataValidation(new_store_data);

		if (!is_data_valid) {
			return;
		}

		setSendingData(true);

		await kilboSignOn(new_store_data)
		.then(async response => {
			if (!response.ok || response.status != 200) {
				throw await response.json();
			}
			toast.success('Se ha dado de alta en Kilbo con exito');
			setSendingData(false);
			return updateLead(email, 'Prueba gratis iniciada');
		})
		.then(response => {
			console.log(response);
			let successful_sign_on_page = `${window.location.origin}/registro_exitoso?brand=${shopName}&user=${username}&passwd=${window.btoa(password)}`;

			window.open(successful_sign_on_page, '_self');
		})
		.catch(error => {
			console.log(error);
			setSendingData(false);
			toast.warn('Ha ocurrido un error. Intente mas tarde, si el error persiste pongase en contacto con kilbo');
		});
	}

    const handleSubmit = (evt) => {
        evt.preventDefault();
        actionContact()
    }

    const select_kilbo_plan = (type_plan) => {
        setPlan(type_plan);
        let dom_input_users_allowed = document.getElementById('amount_users');
 	
        if (type_plan === 'basic') {
            setAmountUsers(2)
            setPlanCost(299);
            dom_input_users_allowed.min = 2;
            dom_input_users_allowed.max = 20;
            dom_input_users_allowed.value = 2;
        } else if (type_plan === 'professional') {
            setAmountUsers(4)
            setPlanCost(799);
            dom_input_users_allowed.min = 4;
            dom_input_users_allowed.max = 100;
            dom_input_users_allowed.value = 4;
        }
    }

    const validateAmountUsers = (amount) => {
        let dom_input_users_allowed = document.getElementById('amount_users');

        setAmountUsers(amount);

        if (plan === 'basic'){
            if ( amount < 2 ){
                setAmountUsers(2);
                dom_input_users_allowed.value = 2;
            } else if ( amount > 20 ) {
                setAmountUsers(20);
                dom_input_users_allowed.value = 20;
            }
            setPlanCost( 299 + ( (Number(dom_input_users_allowed.value) - 2) * 150 ) );
        } else if (plan === 'professional'){
            if ( amount < 4 ) {
                setAmountUsers(4);
                dom_input_users_allowed.value = 4;
            } else if ( amount > 100 ) {
                setAmountUsers(100);
                dom_input_users_allowed.value = 100;
            }
            setPlanCost( 799 + ( (Number(dom_input_users_allowed.value) - 4) * 150 ) );
        }
    }

    const display_zip_code_info = (user_zip=true, zip_code) => {
        if (zip_code.length !== 5 || isNaN(zip_code)) return;

        setBranchZipCode(zip_code);

        fetch(`${api_zip_codes}${zip_code}`)
        .then(zip_code_info => {
            zip_code_info.json()
            .then(zip_json => {
                setBranchColonies(zip_json.colonia);
                setBranchColony(zip_json.colonia[0]);
                setBranchCity(zip_json.municipio.toLowerCase());
                setBranchState(zip_json.estado.toLowerCase());
                setBranchCountry("méxico");
            });
        })
        .catch(e => {
            console.log(e);
        })

    }

    return (
        <div>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <div style={{height:100}}/>
                <h2>Darme de Alta en Kilbo</h2>
                {/* <Banner image={img_contact} imagePhone={img_contact_phone} title={contact_title} subtitle={contact_subtitle}/> */}
                <div style={{paddingBottom:50}}/>
                <Grid container>
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <div className={classes.content}>

							<h3 className={classes.subtitle}>Nombre de tu comercio y sucursal</h3>

							<div className={classes.input_container}>
								<label htmlFor='shop_name'>{form_shop_name}</label>
								<input placeholder={form_shop_name}
									type='text' 
									name="shop_name"
									onChange={e => setShopName(e.target.value)}
									className={classes.input}
									required
									pattern={brand_name_regex.toString().replaceAll("/", "")}
									title="Solo letras (sin acentos), numeros y espacios permitidos, de 2 a 100 caracteres."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="branch_name">{form_shop_branch}</label>
								<input placeholder={form_shop_branch}
									type='text' 
									name="branch_name"
									onChange={e => setBranchName(e.target.value)}
									className={classes.input}
									value={branchName}
									required
									pattern={branch_name_regex.toString().replaceAll("/", "")}
									title="Ingresa el nombre de la sucursal sin caracteres especiales ni espacios, de 2 a 32 caracteres."
								/>
							</div>

							<h3 className={classes.subtitle}>
								Plan para tu cuenta
								<Tooltip
									title={
										<React.Fragment>
											{"El plan basico incluye 2 usuarios totales"}<br></br>
											{"El plan profesional incluye 4 usuarios totales"}<br></br>
											{"Cada usuario adicional tiene un costo de MXN 150.00."}<br></br>
											{"En la parte inferior puedes ver el costo final de tu plan."}<br></br>
											{"Desde tu punto de venta podras actualizar tu plan acorde a tus necesidades."}
										</React.Fragment>
									}
									placement="top">
										<Info />
								</Tooltip>
							</h3>

							<div className={classes.input_container}>
								<label htmlFor='shop_name'>{form_shop_plan}</label>
								<select
									name="shop_plan"
									onChange={e => select_kilbo_plan(e.target.value)}
									className={classes.input}
									defaultValue={plan}
								>
									<option value="basic">Plan Basico (MXN 299.00)</option>
									<option value="professional">Plan Profesional (MXN 799.00 )</option>
								</select>
							</div>

							<div className={classes.input_container}>
								<label htmlFor='shop_name'>{form_shop_users}</label>
								<input placeholder={form_shop_users}
									type='number'
									id="amount_users"
									name="amount_users"
									onInput={e => validateAmountUsers(e.target.value)}
									onChange={e => validateAmountUsers(e.target.value)}
									className={classes.input}
									value={amountUsers}
									min={plan === 'basic' ? 2 : 4}
									max={plan === 'basic' ? 20 : 100}
								/>
							</div>


							<h3 className={classes.subtitle}>Datos de usuario</h3>

							<div className={classes.input_container}>
								<label htmlFor="username">{form_shop_username}</label>
								<input placeholder={form_shop_username}
									type='text' 
									name="username"
									onChange={e => setUsermane(e.target.value)}
									className={classes.input}
									required
									pattern={username_regex.toString().replaceAll("/", "")}
									title="Solo letras (sin acentos) y numeros permitidos, de 4 a 16 caracteres."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="user_password">{form_shop_password}</label>
								<input placeholder={form_shop_password}
									type='password' 
									name="user_password"
									onChange={e => setPassword(e.target.value)}
									className={classes.input}
									required
									pattern={password_regex.toString().replaceAll("/", "")}
									title="Ingresa una contraseña e minimo 8 caracteres."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="user_password_confirmation">{form_shop_password_confirmation}</label>
								<input placeholder={form_shop_password_confirmation}
									type='password' 
									name="user_password_confirmation"
									onChange={e => setPasswordC(e.target.value)}
									className={classes.input}
									required
									pattern={password_regex.toString().replaceAll("/", "")}
									title="Ingresa una contraseña e minimo 8 caracteres."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="user_email">{form_shop_email}</label>
								<input placeholder={form_shop_email}
									type='text' 
									name="user_email"
									onChange={e => setEmail(e.target.value)}
									className={classes.input}
									value={email}
									required
									pattern={email_regex.toString().replaceAll("/", "")}
									title="Parece que el email contiene caracteres no permitidos."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="user_name">{form_shop_user_name}</label>
								<input placeholder={form_shop_user_name}
									type='text' 
									name="user_name"
									onChange={e => setName(e.target.value)}
									className={classes.input}
									value={name}
									required
									pattern={name_regex.toString().replaceAll("/", "")}
									title="Ingresa el nombre(s) sin caracteres especiales, de 2 a 32 caracteres."
								/>
							</div>

							<div className={classes.input_container}>
								<label htmlFor="user_lastname">{form_shop_user_lname}</label>
								<input placeholder={form_shop_user_lname}
									type='text' 
									name="user_lastname"
									onChange={e => setLastname(e.target.value)}
									className={classes.input}
									value={lastname}
									required
									pattern={name_regex.toString().replaceAll("/", "")}
									title="Ingresa los apellidos sin caracteres especiales, de 2 a 32 caracteres."
								/>
							</div>


                            {/* <div className={classes.input_container}>
                                <label htmlFor="branch_street">{form_street}</label>
                                <input placeholder={form_street}
                                    type='text' 
                                    name="branch_street"
                                    onChange={e => setBranchStreet(e.target.value)}
                                    className={classes.input}
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_ext_number">{form_ext_number}</label>
                                <input placeholder={form_ext_number}
                                    type='text' 
                                    name="branch_ext_number"
                                    onChange={e => setBranchExtNumber(e.target.value)}
                                    className={classes.input}
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_int_number">{form_int_number}</label>
                                <input placeholder={form_int_number}
                                    type='text' 
                                    name="branch_int_number"
                                    onChange={e => setBranchIntNumber(e.target.value)}
                                    className={classes.input}
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_zip_code">{form_zip_code}</label>
                                <input placeholder={form_zip_code}
                                    type='text' 
                                    name="branch_zip_code"
                                    onChange={e => display_zip_code_info(false, e.target.value)}
                                    className={classes.input}
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_neighborhood">{form_neighborhood}</label>
                                <select
                                    name="branch_neighborhood"
                                    onChange={e => setBranchColony(e.target.value)}
                                    className={classes.input}>
                                    {branchColonies.map((element, i) => {
                                        return (<option value={element.toLowerCase()} key={i}>{element}</option>)
                                    })}
                                </select>
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_city">{form_city}</label>
                                <input placeholder={form_city}
                                    type='text' 
                                    name="branch_city"
                                    onChange={e => setBranchCity(e.target.value)}
                                    className={classes.input}
                                    value={branchCity}
                                    disabled
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_state">{form_state}</label>
                                <input placeholder={form_state}
                                    type='text' 
                                    name="branch_state"
                                    onChange={e => setBranchState(e.target.value)}
                                    className={classes.input}
                                    value={branchState}
                                    disabled
                                    />
                            </div>

                            <div className={classes.input_container}>
                                <label htmlFor="branch_country">{form_country}</label>
                                <input placeholder={form_country}
                                    type='text' 
                                    name="branch_country"
                                    onChange={e => setBranchCountry(e.target.value)}
                                    className={classes.input}
                                    value={branchCountry}
                                    disabled
                                    />
                            </div> */}



                            <h4 className={classes.subtitle}>Datos de Inicio de Sesion</h4>
                            <p className={classes.subtitle}>Una vez tu tienda se haya registrado en la red Kilbo, podras iniciar sesion con los siguientes datos:</p>
                            <p className={classes.login_info}>ID Tienda: <b><span>{shopName.toLowerCase().replace(/ /gi, '')}</span></b></p>
                            <p className={classes.login_info}>Usuario: <b><span>{username}</span></b></p>
                            <p className={classes.login_info}>Contraseña: <b><span>{password}</span></b></p>
                            
                        </div>
                        
                        <div className={classes.content2}>
                            <h2>Costo mensual del plan: $ {planCost.toFixed(2)} MXN</h2>
                        </div>

						<div className={classes.content2}>
							<button
								type='submit'
								disabled={sending}
								className={classes.sendButton}
							>
								{start}
							</button>
						</div>

                        <div className={ sending ? `${classes.content2}`: `${classes.display_none}`} >
                            <CircularProgress color="secondary" />
                        </div>

						<div className={classes.content2}>
							<p className={classes.warn_text}>
								** El proceso puede demorar un par de minutos, por favor sea paciente. **<br></br>
								** Una vez creada su tienda, agrege la direccion de la sucursal, esto para desplegarla en sus tickets y ordenes de compra, y tambien poder hacer uso de Kilbo Envios **
							</p>
						</div>
                    </form>
                </Grid>

            </Container>
            <div style={{height:200}}/>
        </div>
    );

}

export default SignOn;