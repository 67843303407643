import React, {useState, useEffect} from 'react';
import { Container } from '@material-ui/core';
import {
    title_privacy,
    subtitle_privacy,
    privacy_title,
    privacy_opening,
    privacy_ending,
    privacy_first_question,
    privacy_first_anwser,
    privacy_second_question,
    privacy_second_anwser,
    privacy_third_question,
    privacy_third_anwser,
    privacy_fourth_question,
    privacy_fourth_anwser,
    privacy_fifth_question,
    privacy_fifth_anwser,
    privacy_sixth_question,
    privacy_sixth_anwser,
    privacy_seventh_question,
    privacy_seventh_anwser,
} from '../../values';
import Banner from '../global/Banner'

const Privacy = () => {
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div style={{minHeight:'80vh'}}>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner  title={title_privacy} subtitle={subtitle_privacy}/>
                <div style={{paddingTop:100}}>
                    <p style={{color:'rgb(44, 49, 56)', fontSize:'25px', fontFamily:'SemiBold', textAlign: 'center'}}>
                        { privacy_title }
                    </p>


                    <p style={{color:'rgb(44, 49, 56)', textAlign: 'justify'}}>
                        { privacy_opening }
                    </p>
                    

                    <ul style={{paddingTop:'20px', paddingBottom:'20px', listStyleType:'none'}}>
                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_first_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_first_anwser }
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_second_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_second_anwser }
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_third_question }
                            </p>
                            <p
                                style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}
                                dangerouslySetInnerHTML={{__html: privacy_third_anwser}}>
                                {/* { privacy_third_anwser } */}
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_fourth_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_fourth_anwser }
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_fifth_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_fifth_anwser }
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_sixth_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_sixth_anwser }
                            </p>
                        </li>

                        <li>
                            <p style={{color:'rgb(44, 49, 56)', fontFamily:'SemiBold'}}>
                                { privacy_seventh_question }
                            </p>
                            <p style={{whiteSpace:'pre-line', textAlign: 'justify', fontFamily:'Regular', color:'rgb(44, 49, 56)'}}>
                                { privacy_seventh_anwser }
                            </p>
                        </li>
                    </ul>
                    

                    <p style={{color:'rgb(44, 49, 56)', textAlign: 'end'}}>
                        { privacy_ending }
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default Privacy;