import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Colors } from '../../values';
import '../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    content:{
        width:400, 
        height:300, 
        display:'flex', 
        alignItems:'center', 
        position:'absolute',
        marginLeft:100,
        [theme.breakpoints.down('xs')]: {
            marginLeft:10,
        },
    },
    content2:{
        [theme.breakpoints.down('xs')]: {
            width:330,
        },
    },
    title:{
        color: Colors.gray, 
        fontSize:80, 
        fontFamily:'Bold',
        [theme.breakpoints.down('xs')]: {
            fontSize:60, 
        },
    },
    line:{
        width:'100%', 
        height:5, 
        backgroundColor:Colors.blue,
        [theme.breakpoints.down('xs')]: {
            width:250, 
        },
    },
    navbar:{
        height:300, 
        borderRadius:20, 
        background:Colors.grayExtraLight, 
        position:"relative", 
        overflow:'hidden', 
        marginTop:200,
        [theme.breakpoints.down('xs')]: {
            marginTop:150, 
        },
    },
}))

function BannerPicture ({image, imagePhone, title, subtitle}) {
    const classes = useStyles()
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    return (
        <div>
            <div className={classes.navbar}>
                <img   src={size < 600 ? imagePhone : image} style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} /> 
                <div className={classes.content}>
                    <div className={classes.content2}>
                        <span className={classes.title} > {title} </span>
                        <div className={classes.line}/>
                        <br/>
                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Regular'}} > {subtitle} </span>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BannerPicture;