import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Colors} from '../../../values';
import {home_featues, kilbo, home_offer} from '../../../values';
import '../../../fonts/font.css';
import Lottie from 'react-lottie';

const useStyles = makeStyles(theme => ({
    card:{
        height:350,
        width:'90%',
        [theme.breakpoints.down('md')]: {
            height:400,
            marginLeft:40,
            marginBottom:50,
        },
        [theme.breakpoints.down('xs')]: {
            height:400,
            width:'100%',
            marginBottom:50,
            marginLeft:0,
        },
    },
}));

function Features () {
    const classes = useStyles()
    const [playFeatureA, setPlayFeatureA] = useState(true) 
    const [playFeatureB, setPlayFeatureB] = useState(true) 
    const [playFeatureC, setPlayFeatureC] = useState(true) 

    const getPlayAnimation = (index) => {
        switch (index) {
            case 0: return playFeatureA; break;
            case 1: return playFeatureB; break;
            default: return playFeatureC; break;
        }
    }

    const getAnimation = (path, index) => {
        return {
            loop: true,
            autoplay: false,
            animationData: path,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        }
    };

    const onMouseChange = (index, action) => {
        switch (index) {
            case 0: return setPlayFeatureA(action); break;
            case 1: return setPlayFeatureB(action); break;
            default: return setPlayFeatureC(action); break;
        }
    };

    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    
    return (
        <div style={{marginTop:105}}>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <span style={{color: Colors.gray, fontSize:25, fontFamily:'Medium'}} > {home_offer} </span>
                <br/>
                <span style={{color: Colors.gray, fontSize:70, fontFamily:'Bold'}} > {kilbo} </span>
                <div style={{height:50}}/>
                <Grid container>
                    { home_featues.map((item, index)=> 
                        <Grid item xs={12} md={6} lg={4} key={index}>
                            <div >
                                <div style={{borderRadius:15, borderColor:Colors.grayExtraLight, border: '1px solid #F2F2F2'}} className={classes.card}>
                                    <div 
                                        onMouseEnter={ () => onMouseChange(index, false)} 
                                        onMouseLeave={ () => onMouseChange(index, true)} 
                                        style={{width:'100%', textAlign:'center'}}>
                                        <Lottie  
                                            isPaused={getPlayAnimation(index)}
                                            options={getAnimation(item.image, index)} 
                                            style={{width:200, height:200}}/>
                                    </div> 
                                    <div style={{height:20}}/>
                                    <div style={{width:'80%', marginLeft:'10%'}}>
                                        <span style={{color: Colors.grayDark, fontSize:25, fontFamily:'SemiBold'}} > {item.title} </span>
                                        <div style={{height:5}}/>
                                        <span style={{color: Colors.gray, fontSize:16, fontFamily:'Regular', textAlign:'justify'}} > {item.message} </span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );

}

export default Features;