import React, { useState, useEffect} from 'react';
import { Container, Collapse, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {Colors, questions_title, questions_subtitle, questions, ic_expand, ic_collapse, start} from '../../values';
import {questions_partner, questions_partner_description} from '../../values';
import '../../fonts/font.css';
import Banner from '../global/Banner'
import Button from '../global/ButtonOutline'
import ModalForm from '../global/ModalForm'

const useStyles = makeStyles(theme => ({
    content1:{
        width:'80%', 
        marginLeft:'10%', 
        borderRadius:20,
        height:180, 
        background:Colors.green,
        [theme.breakpoints.down('md')]: {
            height:200, 
        },
        [theme.breakpoints.down('sm')]: {
            height:240, 
            marginLeft:0, 
            width:'100%',
        },
    },
    content2:{
        width:'80%', 
        marginLeft:'10%', 
        marginTop:100, 
        borderRadius:20, 
        boxShadow:'0px 4px 5px 3px rgba(0,0,0,0.17)', 
        overflow:'hidden',
        [theme.breakpoints.down('sm')]: {
            width:'100%', 
            marginLeft:0, 
        },
    },
    content3:{
        marginLeft:'5%', 
        marginTop:40, 
        width:'90%', 
        display:'inline-block',
        [theme.breakpoints.down('sm')]: {
            marginLeft:0, 
            marginLeft:'2%',
            width:'70%',
        },
    },
    content4:{
        marginLeft:50, 
        color: Colors.gray, 
        fontSize:16, 
        fontFamily:'Regular', 
        textAlign:'justify',
        // whiteSpace:'pre',
        [theme.breakpoints.down('md')]: {
            whiteSpace:'normal',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:0,
            whiteSpace:'normal',
        },
    },
}))

function Questions () {
    const classes = useStyles()
    const [showQuestion1, setShowQuestion1] = useState(false) 
    const [showQuestion2, setShowQuestion2] = useState(false) 
    const [showQuestion3, setShowQuestion3] = useState(false) 
    const [showQuestion4, setShowQuestion4] = useState(false) 
    const [showQuestion5, setShowQuestion5] = useState(false) 
    const [showQuestion6, setShowQuestion6] = useState(false) 
    const [showQuestion7, setShowQuestion7] = useState(false) 
    const [showQuestion8, setShowQuestion8] = useState(false) 
    const [showQuestion9, setShowQuestion9] = useState(false) 
    const [open, setOpen] = useState(false)

    const getShowQuestion = (index) => {
        switch (index) {
            case 0: return showQuestion1; break;
            case 1: return showQuestion2; break;
            case 2: return showQuestion3; break;
            case 3: return showQuestion4; break;
            case 4: return showQuestion5; break;
            case 5: return showQuestion6; break;
            case 6: return showQuestion7; break;
            case 7: return showQuestion8; break;
            default: return showQuestion9; break;
        }
    }
    const changeShowQuestion = (index) => {
        switch (index) {
            case 0: return  setShowQuestion1(!showQuestion1); break;
            case 1: return  setShowQuestion2(!showQuestion2); break;
            case 2: return  setShowQuestion3(!showQuestion3); break;
            case 3: return  setShowQuestion4(!showQuestion4); break;
            case 4: return  setShowQuestion5(!showQuestion5); break;
            case 5: return  setShowQuestion6(!showQuestion6); break;
            case 6: return  setShowQuestion7(!showQuestion7); break;
            case 7: return  setShowQuestion8(!showQuestion8); break;
            default: return setShowQuestion9(!showQuestion9); break;
        }
    }
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])
    const openModal = () =>{
        setOpen(true)
    }
    const callback = () =>{
        setOpen(false);
    }
    return (
        <div > 
            {
                open &&
                    <ModalForm modal={open} parentCallback={callback} />
            }
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <Banner  title={questions_title} subtitle={questions_subtitle}/>
                <div className={classes.content2}>
                    { questions.map((item, index)=> 
                        <div key={index} onClick={() => changeShowQuestion(index)} style={{cursor:'pointer'}}>
                            <div className={classes.content3}>
                                <span style={{color: Colors.gray, fontSize:16, fontFamily:'Regular', textAlign:'justify'}} > {item.question} </span>
                            </div>
                            <div style={{textAlign:'right'}}>
                                <img alt="arrow"  src={getShowQuestion(index) ? ic_collapse : ic_expand} 
                                    style={{height:15, cursor:'pointer', transition:'opacity 0.5s linear', marginRight:20}} 
                                    onClick={() => changeShowQuestion(index)}/> 
                            </div>
                            <div style={{width:'100%', marginTop:40, height:1, backgroundColor:Colors.blueLight}}/>
                            <Collapse in={getShowQuestion(index)} timeout="auto" unmountOnExit>
                                <div style={{backgroundColor:Colors.blueLight, padding:20}}>
                                    <div className={classes.content4} dangerouslySetInnerHTML={{ __html: item.answer}} />
                                </div>

                            </Collapse>
                        </div>
                    )}
                </div>
                <div style={{height:100}}/>
                <div className={classes.content1}>
                    <div style={{height:30}}/>
                    <span style={{color: Colors.white, marginLeft:30, fontSize:30, fontFamily:'Bold', textAlign:'justify'}} > {questions_partner} </span>
                    <div style={{height:10}}/>
                    <div style={{marginLeft:30}}>
                        <span style={{ color: Colors.white, fontSize:16, fontFamily:'Regular', textAlign:'justify'}} > {questions_partner_description} </span>
                    </div>
                    <div onClick={openModal} style={{marginTop:10, marginLeft:30}}>
                        <Button text={start} color={'whiteGreen'}/>
                    </div>
                </div>
                <div style={{height:200}}/>
            </Container>
        </div>
    );

}

export default Questions;