import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Toolbar, Grid } from '@material-ui/core';
import {Colors, subscribe_message, subscribe_email, subscribe_email_sample, imageSmile, subscribe_button} from '../../values';
import '../../fonts/font.css';
import Lottie from 'react-lottie';
import document from '../../animations/ic_document.json'
import Button from '../global/ButtonOutline'

import {postSubscribe, subscribeValidations} from '../../utils/BaseBuilder';


const useStyles = makeStyles(theme => ({
    inEmail: { 
        color:Colors.white,
        textAlign:'center', 
        width:'50%', 
        left:'10%',
        height:40, 
        borderWidth:0, 
        background:Colors.greenLight,
        borderRadius:0, 
        padding:5, 
        fontFamily:'regular', 
        fontSize:20,
        [theme.breakpoints.down('xs')]: {
            width:'100%', 
            left:0,
            paddingLeft:0,
            paddingRight:0,
        },
        '&::placeholder': { 
            color: Colors.white, 
            opacity:0.7
        }, 
    },
    content:{
        [theme.breakpoints.down('xs')]: {
            paddingBottom:250,
        },
    },
    contet1:{
        width:'80%', 
        marginLeft:'15%',
        height:250, 
        marginTop:150, 
        marginBottom:150,
        [theme.breakpoints.down('md')]: {
            width:'100%', 
            marginLeft:0,
        },
    },
    contet2:{
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'90%', 
        },
    },
    contet3:{
        borderRadius:10, 
        position:'relative',
        paddingBottom:10,
    },
    contet4:{
        display:'inline-block', 
        marginLeft:20,
        [theme.breakpoints.down('xs')]: {
            marginLeft:0, 
            marginTop:20,
        },
    },
    lottie:{
        [theme.breakpoints.down('xs')]: {
            textAlign:'center'
        },
    },
    imageAnimation:{
        width:100,
        position:'absolute', 
        top:100,
        [theme.breakpoints.down('xs')]: {
            left:'50%',
            transform:'translate(-50%, 0%)',
            top:390,
        },
    },
}));

function Subscribe () {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: document,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: imageSmile,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const classes = useStyles()
    const [counter, setCounter] = useState(0);
    const [size, setSize] = useState(window.innerWidth)
    const [email, setEmail] = useState("");
    const [flag, setFlag] = useState(false);
    const [buttonClick, setbuttonClick] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const myWidth  = window.innerWidth;
            setSize(myWidth)
        })
    },[window])

    const actionSuscribe = async() =>{
        const flag = subscribeValidations(email)
        if(flag === true){
            const res = await postSubscribe(email)
        }
        setCounter(0)
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(buttonClick === false){
            setbuttonClick(true)
            actionSuscribe()
        }
    }

    useEffect(() => {
        const timer = counter < 2 && setInterval(() => setbuttonClick(false), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    return (
        <div className={classes.content}>
            <Container maxWidth={size < 1921 ? 'lg' : 'xl'}>
                <div className={classes.contet1}>
                    <div style={{backgroundColor:Colors.green}} className={classes.contet3}>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <div style={{position:'relative', height:200}}>
                                    <div style={{width:200, position:'absolute', left:'50%', transform:'translate(-50%, 0%)'}} > 
                                        <Lottie options={defaultOptions} /> 
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Container maxWidth="xl">
                                    <div style={{marginTop:20}}>
                                        <span style={{color: Colors.white, fontSize:16, fontFamily:'Regular'}}> {subscribe_message} </span>
                                    </div>
                                    <div style={{marginTop:20}}>
                                        <span style={{color: Colors.white, fontSize:16, fontFamily:'Bold'}} > {subscribe_email} </span>
                                    </div>
                                    <div style={{marginTop:20}}>
                                        <form onSubmit={handleSubmit}>
                                            <input className={classes.inEmail} 
                                                onChange={e => setEmail(e.target.value)}
                                                name="email"
                                                placeholder={subscribe_email_sample} 
                                                type='text' 
                                            />
                                            <div className={classes.contet4}> 
                                                {
                                                    flag ?
                                                        <div className={classes.imageAnimation} >
                                                            <Lottie options={defaultOptions2} />
                                                        </div>
                                                    :
                                                        <Button color={'whiteGreen'} text={subscribe_button} type='submit' /> 
                                                }
                                            </div>
                                        </form>
                                        
                                    </div>
                                </Container>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        </div>
    );

}

export default Subscribe;